export enum MateBeoordeling { Geen = 'Niet beoordeeld', Volledig = 'Volledig beoordeeld', Voorlopig = 'Voorlopig beoordeeld' }
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const COLORS = [
    '#3079f0',
    '#f0c330',
    '#c12cf2',
    '#13cf5b',
    '#f78c00',
    '#6e4510',
    '#a60058',
    '#88a600',
    '#6bdfe3'
];