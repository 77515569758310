import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, faSquareCheck, faSquare, faSquareMinus, faCircle, faBan, faFilter as faDisabledFilter, faPencil, faWandSparkles } from '@fortawesome/pro-light-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import Meetpunt from 'models/Meetpunt';
import moment from 'moment';
import { gwm_api } from 'protobuf';
import { useEffect, useRef, useState, PropsWithChildren, useCallback } from 'react';
import { ChartItem } from '../models/ChartValue';
import ValidationGraph from './ValidationGraph';
import { GwmApi } from 'general/api';
import { RefUnit } from '..';
import { Dropdown } from 'react-bootstrap';
import { isEqual, sortBy, sortedUniq } from 'lodash';

interface ValidationEditorProps {
    chartData: ChartItem[];
    data: [Meetpunt, gwm_api.SampleCollection];
    unit: RefUnit;
    onFinish?: (success: boolean) => void;
    onLoading?: (state: boolean) => void;
}

interface ValidationEditorFilter {
    value?: (gwm_api.Validation.Score | undefined)[],
    state?: (gwm_api.Validation.State | undefined)[],
    reason?: (gwm_api.Validation.Reason | undefined)[],
    interpolation?: (gwm_api.Validation.Interpolation | undefined)[]
}

function ValidationEditor(props: ValidationEditorProps) {
    const { chartData, data, unit, onLoading } = props;

    const [samples, setSamples] = useState<[gwm_api.ISample, gwm_api.IValidation, boolean][]>();
    const [chartSamplesFull, setChartSamplesFull] = useState<ChartItem[]>();
    const [chartSelection, setChartSelection] = useState<ChartItem[]>();
    const [filtered, setFiltered] = useState<[gwm_api.ISample, gwm_api.IValidation, boolean][]>([]);
    const [selected, setSelected] = useState<number>(0);
    const [filter, setFilter] = useState<ValidationEditorFilter>({});
    const [stage, setStage] = useState<'selection' | 'editing'>('selection');
    
    const [editScore, setEditScore] = useState<gwm_api.Validation.Score>();
    const [editState, setEditState] = useState<gwm_api.Validation.State>();
    const [editReason, setEditReason] = useState<gwm_api.Validation.Reason>();
    const [editLimit, setEditLimit] = useState<number>();
    const [editInterpolation, setEditInterpolation] = useState<gwm_api.Validation.Interpolation>();

    const prevSamples = useRef<[gwm_api.ISample, gwm_api.IValidation, boolean][] | undefined>();
    const prevChartData = useRef<ChartItem[]>();
    const prevFilter = useRef<ValidationEditorFilter>(filter);
    const prevStage = useRef<'selection' | 'editing'>(stage);

    const resetFilter = useCallback(() => {
        setSelected(samples?.length ?? 0);
        setFilter({});
    }, [samples]);

    useEffect(() => {
        if (prevSamples.current != samples) {
            resetFilter();

            prevSamples.current = samples;
        }
    }, [resetFilter, samples]);

    useEffect(() => {
        if (prevChartData.current != chartData) {
            setChartSamplesFull(chartData.map(x => ({ ...x, values: [x.values[((data?.[0].filterNr ?? 1) as number) - 1]]})));
            prevChartData.current = chartData;
        }
    }, [chartData, data]);

    useEffect(() => {
        if (prevFilter.current != filter) {
            const newFiltered: [gwm_api.ISample, gwm_api.IValidation, boolean][] = [];
            let count = 0;
            if (samples) {
                for (const sample of samples) {
                    if ((!filter.value?.length || filter.value?.includes(sample[1].value ?? undefined)) &&
                        (!filter.state?.length || filter.state?.includes(sample[1].state ?? undefined)) &&
                        (!filter.reason?.length || filter.reason?.includes(sample[1].reason ?? undefined)) &&
                        (!filter.interpolation?.length || filter.interpolation?.includes(sample[1].interpolation ?? undefined))) {

                        newFiltered.push(sample);
                        if (sample[2]) count++;
                    } else {
                        sample[2] = false;
                    }
                }
            }
    
            setSelected(count);
            setFiltered(newFiltered);

            prevFilter.current = filter;
        }
    }, [samples, filter]);

    useEffect(() => {
        if (prevStage.current != stage) {
            if (stage == 'editing') {
                const items = filtered.filter(([,,s]) => s).map(([,x]) => x);
                const available = {
                    value: sortedUniq(sortBy(items.map(x => x.value ?? undefined))),
                    state: sortedUniq(sortBy(items.map(x => x.state ?? undefined))),
                    reason: sortedUniq(sortBy(items.map(x => x.reason ?? undefined))),
                    interpolation: sortedUniq(sortBy(items.map(x => x.interpolation ?? undefined))),
                    limit: sortedUniq(sortBy(items.map(x => x.limit ?? undefined))),
                };

                setEditScore(available.value.length == 1 ? available.value[0] : undefined);
                setEditState(available.state.length == 1 ? available.state[0] : undefined);
                setEditReason(available.reason.length == 1 ? available.reason[0] : undefined);
                setEditInterpolation(available.interpolation.length == 1 ? available.interpolation[0] : undefined);
                setEditLimit(available.limit.length == 1 ? available.limit[0] : undefined);
            }
            // else if (stage == 'selection') {
            //     resetFilter();
            // }

            prevStage.current = stage;
        }
    }, [filtered, resetFilter, stage]);

    const onValidationGraphSelectionChanged = useCallback(async (items?: gwm_api.ISample[]): Promise<void> => {
        if (!items?.length) {
            setSamples(undefined);
            return;
        }
    
        onLoading?.(true);
    
        const [measurepoint] = data;
        const dict: Record<number, [gwm_api.ISample, gwm_api.IValidation | undefined]> = 
            Object.assign({}, ...items.map(s => ({ [Number(s.timestamp)]: [s, undefined] })));
    
        const response = await GwmApi.getValidationInfo({
            project: measurepoint.projectNr,
            boring: measurepoint.boringNr as number,
            filter: measurepoint.filterNr as number,
            timestamps: Object.keys(dict).map(Number)
        });
    
        if (response.data) {
            for (const item of response.data.values) {
                dict[Number(item.timestamp)][1] = item;
            }
        }
    
        const values = Object.values(dict).filter(([,v]) => v)
            .map(x => [...x, true] as [gwm_api.ISample, gwm_api.IValidation, boolean]);
    
        setSamples(values);
        onLoading?.(false);
    }, [onLoading, data]);

    const runAutoControl = useCallback(async (): Promise<void> => {
        const [measurepoint] = data;
        await GwmApi.updateAutoControl({
            project: measurepoint.projectNr,
            boring: measurepoint.boringNr as number,
            filter: measurepoint.filterNr as number
        });

        if (chartSamplesFull) {
            for (const chartItem of chartSamplesFull) {
                const value = chartItem.values[0];
                value.released = value.valid ? value.raw : null;
            }

            setChartSamplesFull([...chartSamplesFull]);
        }

    }, [chartSamplesFull, data]);

    const cancelChanges = useCallback(async (): Promise<void> => {
        setStage('selection');
    }, []);

    const saveChanges = useCallback(async (): Promise<void> => {
        if (!filtered.length) return;

        onLoading?.(true);

        const [measurepoint] = data;
        const items = filtered.filter(([,,s]) => s).map(([,x]) => x);

        const changeRegistry: Record<number, [boolean, boolean]> = {};
        for (const item of items) {
            item.value = editScore ?? gwm_api.Validation.Score.SCORE_NOT_SPECIFIED;
            item.state = editState ?? gwm_api.Validation.State.STATE_NOT_SPECIFIED;
            item.reason = editReason ?? gwm_api.Validation.Reason.REASON_NOT_SPECIFIED;
            item.limit = editLimit ?? undefined;
            item.interpolation = editInterpolation ?? gwm_api.Validation.Interpolation.INTERPOLATION_DISCONTINUOUS;

            changeRegistry[Number(item.timestamp)] = [
                item.value == gwm_api.Validation.Score.SCORE_VALID,
                item.state == gwm_api.Validation.State.STATE_APPROVED
            ];
        }

        if (filter.value && !filter.value.includes(editScore)) 
            filter.value.push(editScore);
        if (filter.state && !filter.state.includes(editState)) 
            filter.state.push(editState);
        if (filter.reason && !filter.reason.includes(editReason)) 
            filter.reason.push(editReason);
        if (filter.interpolation && !filter.interpolation.includes(editInterpolation)) 
            filter.interpolation.push(editInterpolation);

        if (chartSamplesFull) {
            for (const chartItem of chartSamplesFull) {
                const changeState = changeRegistry[chartItem.time];
                if (!changeState) continue;

                const [isValid, isApproved] = changeRegistry[chartItem.time];
                if (typeof isValid == 'boolean') {
                    chartItem.values[0].valid = isValid ? chartItem.values[0].raw : null;
                    chartItem.values[0].released = isApproved ? chartItem.values[0].raw : null;
                }
            }
        }

        const collection = new gwm_api.ValidationCollection();
        collection.values.push(...items);
        
        const response = await GwmApi.updateValidationInfo({
            project: measurepoint.projectNr,
            boring: measurepoint.boringNr as number,
            filter: measurepoint.filterNr as number,
            collection: collection
        });

        if (!(response.data ?? response.success)) {
            console.error('failed to update validation state for selected measurements.');
        }

        setStage('selection');
        if (chartSamplesFull) {
            setChartSamplesFull([...chartSamplesFull]);
        }

        onLoading?.(false);
    }, [chartSamplesFull, data, editInterpolation, editLimit, editReason, editScore, editState, filter.interpolation, filter.reason, filter.state, filter.value, filtered, onLoading]);

    return (
        <div className="validation-editor d-flex flex-column h-100 overflow-hidden">
            {stage == 'selection' ? (<>
                {chartSamplesFull?.length ? 
                    <ValidationGraph
                        info={data} unit={unit}
                        data={chartSamplesFull}
                        selection={chartSelection}
                        onSelection={(a, b) => {
                            setChartSelection(b);
                            onValidationGraphSelectionChanged(a);
                        }} /> 
                    : undefined}

                <div className="mt-3 overflow-auto h-100">
                    <div className="d-flex align-items-end justify-content-between w-100 mb-1 mx-1">
                        <div className="selection-info-badge">
                            <span className="badge badge-secondary">
                                {app.translator.translate('Geselecteerd')}: {selected} / {samples?.length ?? 0}
                            </span>
                        </div>

                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-outline-primary" disabled={!samples?.length} onClick={() => setStage('editing')}>
                                <FontAwesomeIcon icon={faPencil} /> {app.translator.translate('Bewerken')}
                            </button>

                            <button className="btn btn-primary btn-sm" onClick={() => runAutoControl()}>
                                <FontAwesomeIcon icon={faWandSparkles} /> {app.translator.translate('Automatische Kwaliteitsbeoordeling')}
                            </button>
                        </div>
                    </div>
                        
                    {samples?.length ? (
                        <table className="table table-sm table-hover">
                            <thead className="sticky-top">
                                <tr>
                                    <th className="text-center">
                                        <a href="#" onClick={() => {

                                            if (selected != filtered.length) {
                                                filtered.forEach(x => x[2] = true);
                                                setSelected(filtered.length);
                                            } else {
                                                filtered.forEach(x => x[2] = false);
                                                setSelected(0);
                                            }
                                        
                                        }}>
                                            <FontAwesomeIcon icon={samples.length == selected ? faSquareCheck : selected ? faSquareMinus : faSquare} size="lg" />
                                        </a>
                                    </th>
                                    <th scope="col">Datum</th>
                                    <th scope="col"></th>
                                    <th scope="col">Grondwaterstand</th>

                                    <FilterCol values={[
                                        gwm_api.Validation.Score.SCORE_NOT_SPECIFIED,
                                        gwm_api.Validation.Score.SCORE_VALID, 
                                        gwm_api.Validation.Score.SCORE_INVALID, 
                                        gwm_api.Validation.Score.SCORE_REMOVED
                                    ].map(v => [v, getValidationLabel(v), filter.value?.includes(v) ?? false])} onFilter={(f) => {
                                        const newFilter: ValidationEditorFilter = { ...filter };
                                        newFilter.value = f.filter(([,s]) => s).map(([v]) => v);
                                        setFilter(newFilter);
                                    }}>
                                    Validatie
                                    </FilterCol>

                                    <FilterCol values={[
                                        gwm_api.Validation.State.STATE_NOT_SPECIFIED,
                                        gwm_api.Validation.State.STATE_APPROVED,
                                        gwm_api.Validation.State.STATE_NOT_RATED,
                                        gwm_api.Validation.State.STATE_REJECTED,
                                        gwm_api.Validation.State.STATE_UNDECIDED
                                    ].map(v => [v, getStateLabel(v), filter.state?.includes(v) ?? false])} onFilter={(f) => {
                                        const newFilter: ValidationEditorFilter = { ...filter };
                                        newFilter.state = f.filter(([,s]) => s).map(([v]) => v);
                                        setFilter(newFilter);
                                    }}>
                                    Status kwaliteitscontrole
                                    </FilterCol>

                                    <FilterCol values={[
                                        gwm_api.Validation.Reason.REASON_NOT_SPECIFIED,
                                        gwm_api.Validation.Reason.REASON_GREATER_THAN_LIMIT,
                                        gwm_api.Validation.Reason.REASON_LESS_THEN_LIMIT
                                    ].map(v => [v, getReasonLabel(v), filter.reason?.includes(v) ?? false])} onFilter={(f) => {
                                        const newFilter: ValidationEditorFilter = { ...filter };
                                        newFilter.reason = f.filter(([,s]) => s).map(([v]) => v);
                                        setFilter(newFilter);
                                    }}>
                                    Censuurreden
                                    </FilterCol>
                                
                                    <th scope="col">Censuurlimiet</th>

                                    <FilterCol values={[
                                        gwm_api.Validation.Interpolation.INTERPOLATION_NOT_SPECIFIED,
                                        gwm_api.Validation.Interpolation.INTERPOLATION_DISCONTINUOUS
                                    ].map(v => [v, getInterpolationLabel(v), filter.interpolation?.includes(v) ?? false])} onFilter={(f) => {
                                        const newFilter: ValidationEditorFilter = { ...filter };
                                        newFilter.interpolation = f.filter(([,s]) => s).map(([v]) => v);
                                        setFilter(newFilter);
                                    }}>
                                    Interpolatietype
                                    </FilterCol>
                                </tr>
                            </thead>

                            <tbody>
                                {filtered.length <= 1000 
                                    ? filtered.map(([x, v, s], i, arr) => 
                                        <ValidationRow key={x.timestamp} sample={x} validation={v} enabled={s} onSelect={v => {
                                            if (v != s) {
                                                arr[i][2] = v;
                                                setSelected(v ? selected + 1 : selected - 1);
                                            }
                                        }} />)
                                    : <tr>
                                        <td colSpan={9}>{app.translator.translate('Meer dan 1000 metingen in geselecteerd gebied. Selectie te groot om weer te geven. Ga door met de huidige selectie, selecteer een kleiner gebied, of stel een filter in op de resultaten.')}</td>
                                    </tr>}
                            </tbody>
                        </table>
                    ) : undefined}
                </div>
            </>) : (
                <div>
                    <div className="form-group">
                        <label>{app.translator.translate('Validatie')}</label>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" className="w-100 text-left" id="validation-edit-value" href="#">
                                {getValidationLabel(editScore)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                                {[
                                    gwm_api.Validation.Score.SCORE_NOT_SPECIFIED,
                                    gwm_api.Validation.Score.SCORE_VALID, 
                                    gwm_api.Validation.Score.SCORE_INVALID, 
                                    gwm_api.Validation.Score.SCORE_REMOVED
                                ].map(v => 
                                    <Dropdown.Item key={v} onClick={() => setEditScore(v)}>
                                        {getValidationLabel(v)}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>   

                    <div className="form-group">
                        <label>{app.translator.translate('Status kwaliteitscontrole')}</label>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" className="w-100 text-left" id="validation-edit-state" href="#">
                                {getStateLabel(editState)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                                {[
                                    gwm_api.Validation.State.STATE_NOT_SPECIFIED,
                                    gwm_api.Validation.State.STATE_APPROVED,
                                    gwm_api.Validation.State.STATE_NOT_RATED,
                                    gwm_api.Validation.State.STATE_REJECTED,
                                    gwm_api.Validation.State.STATE_UNDECIDED
                                ].map(v => 
                                    <Dropdown.Item key={v} onClick={() => setEditState(v)}>
                                        {getStateLabel(v)}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>   

                    <div className="form-group">
                        <label>{app.translator.translate('Censuurreden')}</label>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" className="w-100 text-left" id="validation-edit-reason" href="#">
                                {getReasonLabel(editReason)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                                {[
                                    gwm_api.Validation.Reason.REASON_NOT_SPECIFIED,
                                    gwm_api.Validation.Reason.REASON_GREATER_THAN_LIMIT,
                                    gwm_api.Validation.Reason.REASON_LESS_THEN_LIMIT
                                ].map(v => 
                                    <Dropdown.Item key={v} onClick={() => setEditReason(v)}>
                                        {getReasonLabel(v)}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>   

                    <div className="form-group">
                        <label>{app.translator.translate('Interpolatietype')}</label>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" className="w-100 text-left" id="validation-edit-interpolation" href="#">
                                {getInterpolationLabel(editInterpolation)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                                {[
                                    gwm_api.Validation.Interpolation.INTERPOLATION_NOT_SPECIFIED,
                                    gwm_api.Validation.Interpolation.INTERPOLATION_DISCONTINUOUS
                                ].map(v => 
                                    <Dropdown.Item key={v} onClick={() => setEditInterpolation(v)}>
                                        {getInterpolationLabel(v)}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>   

                    <div className="form-group">
                        <label>{app.translator.translate('Censuurlimiet')}</label>
                        <input type="text" className="form-control" defaultValue={editLimit} onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value)) setEditLimit(value);
                            else setEditLimit(undefined);
                        }} />
                    </div>  

                    <button className="btn btn-secondary btn-sm" onClick={() => cancelChanges()}>
                        {app.translator.translate('Annuleren')}
                    </button> 

                    <button className="btn btn-primary btn-sm" onClick={() => saveChanges()}>
                        {app.translator.translate('Opslaan')}
                    </button>    
                </div>
            )}
        </div>
    );
}

function FilterCol<T extends (string | number)>(props: PropsWithChildren<{ values: [T, string, boolean][], onFilter?: (states: [T, boolean][]) => void; }>): JSX.Element {
    const { values, onFilter, children } = props;

    const [enabled, setEnabled] = useState<boolean[]>(values.map(([,,x]) => x));
    const [isShown, setIsShown] = useState<boolean>(false);

    const prevEnabled = useRef<boolean[]>(enabled);
    const prevValues = useRef<[T, string, boolean][]>(values);

    useEffect(() => {
        if (!isEqual(prevEnabled.current, enabled)) {
            onFilter?.(values.map(([k],i) => [k, enabled[i]]));
            prevEnabled.current = enabled;
        }

        if (!isEqual(prevValues.current, values) && !isEqual(values.map(([,,x]) => x), enabled)) {
            prevEnabled.current = values.map(([,,x]) => x);
            setEnabled(prevEnabled.current);
            prevValues.current = values;
        }
    }, [enabled, values, onFilter]);

    return (
        <th className="th-filter-col" scope="col">
            <Dropdown show={isShown} onToggle={(isOpen, e, metadata) => {
                if (metadata.source != 'select') {
                    setIsShown(isOpen);
                }
            }}>
                <Dropdown.Toggle as="a" id="filter-col-dropdown" href="#" className="text-decoration-none text-reset">
                    {children} <FontAwesomeIcon icon={enabled.some(v => v) ? faFilter : faDisabledFilter} className="ml-2" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {props.values.map(([k, v], i) => 
                        <Dropdown.Item key={k} onClick={() => {
                            const state = [...enabled];
                            state[i] = !state[i];
                            setEnabled(state);
                        }}>
                            <FontAwesomeIcon icon={enabled[i] ? faSquareCheck : faSquare} size="lg" className="mr-2" />
                            {v}
                        </Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        </th>
    );
}

function ValidationRow(props: { sample: gwm_api.ISample; validation?: gwm_api.IValidation; enabled: boolean; onSelect?: (state: boolean) => void; }) {
    const { sample, validation, enabled, onSelect } = props;

    const [selected, setSelected] = useState<boolean>(enabled);
    const prevSelected = useRef<boolean>(selected);

    useEffect(() => {
        if (selected != prevSelected.current) {
            onSelect?.(selected);
            prevSelected.current = selected;
        } else if (enabled != selected) {
            prevSelected.current = enabled;
            setSelected(enabled);
        }
    }, [enabled, onSelect, selected]);

    const value = getValidationLabel(validation?.value);
    const state = getStateLabel(validation?.state);
    const reason = getReasonLabel(validation?.reason);
    const interpolation = getInterpolationLabel(validation?.interpolation);

    return (
        <tr onClick={() => setSelected(!selected)}>
            <td className="text-center">
                <a href="#">
                    <FontAwesomeIcon icon={selected ? faSquareCheck : faSquare} size="lg" />
                </a>
            </td>
            <th scope="row">{moment(sample.timestamp ?? 0).format('DD MMM YYYY HH:mm')}</th>
            <td>
                <ValidationIcon value={validation?.value} />
            </td>
            <td>{sample.value?.toFixed(1) ?? '-'}</td>
            <td>{value}</td>
            <td>{state}</td>
            <td>{reason}</td>
            <td>{validation?.limit ?? '-'}</td>
            <td>{interpolation ?? '-'}</td>
        </tr>
    );
}

function ValidationIcon(props: { value?: gwm_api.Validation.Score | null }): JSX.Element {
    const { value } = props;

    switch (value) {
    case gwm_api.Validation.Score.SCORE_VALID:
        return <FontAwesomeIcon icon={faCircleCheck} className="text-success" />;
    case gwm_api.Validation.Score.SCORE_REMOVED:
        return <FontAwesomeIcon icon={faBan} className="text-danger" />;
    case gwm_api.Validation.Score.SCORE_INVALID:
        return <FontAwesomeIcon icon={faCircleXmark} className="text-danger" />;
    }

    return <FontAwesomeIcon icon={faCircle} />;
}

function getValidationLabel(value?: gwm_api.Validation.Score | null): string {
    switch (value) {
    case gwm_api.Validation.Score.SCORE_VALID:
        return app.translator.translate('Valide', { context: 'GWM' });
    case gwm_api.Validation.Score.SCORE_REMOVED:
        return app.translator.translate('Verwijderd', { context: 'GWM' });
    case gwm_api.Validation.Score.SCORE_INVALID:
        return app.translator.translate('Invalide', { context: 'GWM' });
    }

    return app.translator.translate('-', { context: 'GWM' });
}

function getStateLabel(value?: gwm_api.Validation.State | null): string {
    switch (value) {
    case gwm_api.Validation.State.STATE_APPROVED:
        return app.translator.translate('Goedgekeurd', { context: 'GWM' });
    case gwm_api.Validation.State.STATE_NOT_RATED:
        return app.translator.translate('Nog niet beoordeeld', { context: 'GWM' });
    case gwm_api.Validation.State.STATE_REJECTED:
        return app.translator.translate('Afgekeurd', { context: 'GWM' });
    case gwm_api.Validation.State.STATE_UNDECIDED:
        return app.translator.translate('Onbeslist', { context: 'GWM' });
    }

    return app.translator.translate('-', { context: 'GWM' });
}

function getReasonLabel(value?: gwm_api.Validation.Reason | null): string {
    switch (value) {
    case gwm_api.Validation.Reason.REASON_GREATER_THAN_LIMIT:
        return app.translator.translate('Groter dan limietwaarde', { context: 'GWM' });
    case gwm_api.Validation.Reason.REASON_LESS_THEN_LIMIT:
        return app.translator.translate('Kleiner dan limietwaarde', { context: 'GWM' });
    }

    return app.translator.translate('-', { context: 'GWM' });
}

function getInterpolationLabel(value?: gwm_api.Validation.Interpolation | null): string {
    switch (value) {
    case gwm_api.Validation.Interpolation.INTERPOLATION_DISCONTINUOUS:
        return app.translator.translate('Discontinu', { context: 'GWM' });
    }

    return app.translator.translate('-', { context: 'GWM' });
}

export default ValidationEditor;