import { FormControl, FormControlValue, FormValue } from '@tauw/viewer-base';
import { mapValues } from 'lodash';
import Assessment from 'models/Assessment';
import moment from 'moment';
import { Component } from 'react';
import { MateBeoordeling, DATE_FORMAT, DATETIME_FORMAT } from '../utils/constants';

interface AssessmentEditorProps {
    assessment?: Assessment;
    dateRange?: [Date, Date];

    onCancelClicked?: () => void;
    onCreateClicked?: (assessment: Assessment) => void;
    onUpdateClicked?: (assessment: Assessment) => void;
}

interface AssessmentEditorState {
    form: Form;
}

// todo rename to english
interface Form {
    begindatum: FormValue<string>;
    einddatum: FormValue<string>;
    tijdstipResultaat: FormValue<string>;
    observatietype: FormValue<number>;
    mateBeoordeling: FormValue<number>;
    identificatieOrg: FormValue<string>;
    beoordelingsprocedure: FormValue<number>;
    procestype: FormValue<number>;
}


export default class AssessmentEditor extends Component<AssessmentEditorProps, AssessmentEditorState> {

    constructor(props: Readonly<AssessmentEditorProps>) {
        super(props);

        // Initializes form state based on new or existing assessment
        let form: Form;
        if (props.assessment) {
            form = mapValues(props.assessment, element => element ? ({ value: element, valid: true }) : null) as Form;

            if (form.begindatum?.value)
                form.begindatum.value = moment(form.begindatum.value).format(DATE_FORMAT);

            if (form.einddatum?.value)
                form.einddatum.value = moment(form.einddatum.value).format(DATE_FORMAT);

            if (form.tijdstipResultaat?.value)
                form.tijdstipResultaat.value = moment(form.tijdstipResultaat.value).format(DATETIME_FORMAT);
        }
        else {
            form = {
                begindatum: props.dateRange ? { value: moment(props.dateRange[0]).format(DATE_FORMAT), valid: true } : null,
                einddatum: props.dateRange ? { value: moment(props.dateRange[1]).format(DATE_FORMAT), valid: true } : null,
                tijdstipResultaat: null,
                observatietype: null,
                mateBeoordeling: null,
                identificatieOrg: null,
                beoordelingsprocedure: null,
                procestype: null
            };
        }

        this.state = {
            form: form,
        };

        this.onFormChanged = this.onFormChanged.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    onFormChanged(prop: FormControlValue.Default): void {
        this.setState(state => ({
            form: {
                ...state.form,
                [prop.name]: {
                    value: prop.value,
                    valid: prop.valid
                }
            }
        }));
    }

    onCancelClicked(): void {
        this.props.onCancelClicked?.();
    }

    onSaveClicked(): void {
        // Check validation states
        for (const prop in this.state.form) {
            const value = this.state.form[prop];
            if (!value) continue;

            if (!value.valid) { // prop is null or invalid
                return;
            }
        }

        const assessment = mapValues(this.state.form, prop => prop ? prop.value : undefined) as unknown as Assessment;
        assessment.begindatum = moment.utc(assessment.begindatum, DATE_FORMAT).toDate();
        assessment.einddatum = moment.utc(assessment.einddatum, DATE_FORMAT).toDate();
        assessment.tijdstipResultaat = moment.utc(assessment.tijdstipResultaat, DATETIME_FORMAT).toDate();


        if (this.props.assessment)
            this.props.onUpdateClicked?.(assessment);
        else
            this.props.onCreateClicked?.(assessment);
    }

    render(): JSX.Element {
        const valid = Object.keys(this.state.form).every(key => !this.state.form[key] || this.state.form[key]?.valid);

        return (
            <div className="assessment-editor container">
                <h2 className="mb-5 text-center">{this.props.assessment ? 'Wijzig beoordeling' : 'Nieuwe beoordeling'}</h2>
                <div className="row row-cols-2">
                    <div className="col mb-2">
                        <FormControl.Date displayName={'Begindatum'} dateFormat={DATE_FORMAT}
                            initialValue={this.state.form.begindatum?.value}
                            name={'begindatum'} colWidth={7} feedback={true} feedbackOut={true} required
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Date displayName={'Einddatum'} dateFormat={DATE_FORMAT}
                            initialValue={this.state.form.einddatum?.value}
                            name={'einddatum'} colWidth={7} feedback={true} feedbackOut={true} required
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Date displayName={'Tijdstip resultaat'} dateFormat={DATE_FORMAT} includeTime={'input'} timeUnit={'minutes'}
                            initialValue={this.state.form.tijdstipResultaat?.value}
                            name={'tijdstipResultaat'} colWidth={7} feedback={true} feedbackOut={true} required
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Select displayName={'Observatietype'}
                            selectedOption={this.state.form.observatietype?.value.toString()}
                            options={{
                                1: 'Controlemeting',
                                2: 'Reguliere meting'
                            } as Record<number, string>}
                            name={'observatietype'} colWidth={7} feedback={true} feedbackOut={true} required
                            placeholder={'Selecteer…'}
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Select displayName={'Mate beoordeling'}
                            selectedOption={this.state.form.mateBeoordeling?.value.toString()}
                            options={{
                                1: MateBeoordeling.Volledig,
                                2: MateBeoordeling.Voorlopig
                            } as Record<number, string>}
                            name={'mateBeoordeling'} colWidth={7} feedback={true} feedbackOut={true} required
                            placeholder={'Selecteer…'}
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Text displayName={'Identificatie organisatie'}
                            initialValue={this.state.form.identificatieOrg?.value}
                            name={'identificatieOrg'} colWidth={7} feedback={true} feedbackOut={true} required
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Select displayName={'Procestype'}
                            selectedOption={this.state.form.procestype?.value.toString()}
                            options={{
                                1: 'Algoritme'
                            } as Record<number, string>}
                            name={'procestype'} colWidth={7} feedback={true} feedbackOut={true} required
                            placeholder={'Selecteer…'}
                            onValueChanged={this.onFormChanged} />
                    </div>
                    <div className="col mb-2">
                        <FormControl.Select displayName={'Beoordelingsprocedure'}
                            selectedOption={this.state.form.beoordelingsprocedure?.value.toString()}
                            options={{
                                1: 'Brabant Water 2013',
                                2: 'Eijkelkamp datavalidatie v0.0.9',
                                3: 'Oordeel deskundige',
                                4: 'PMB Protocol datakwaliteitscontrole QC2018v2.0',
                                5: 'RWS AATGrondwater v1.0',
                                6: 'Wareco Water Data validatieprotocol v20200219',
                            } as Record<number, string>}
                            name={'beoordelingsprocedure'} colWidth={7} feedback={true} feedbackOut={true} required
                            placeholder={'Selecteer…'}
                            onValueChanged={this.onFormChanged} />
                    </div>
                </div>
                <div className="col-6 mt-5 mx-auto">
                    <button className="btn btn-dark-cyan btn-block" disabled={!valid} onClick={valid ? this.onSaveClicked : undefined}>
                        {app.translator.translate('Opslaan')}
                    </button>
                    <button className="btn btn-secondary btn-block" onClick={this.onCancelClicked}>
                        {app.translator.translate('Annuleren')}
                    </button>
                </div>
            </div>
        );
    }
}
