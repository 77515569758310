import { WidgetProps, Widget, PanelLoader } from '@tauw/viewer-base';
import { faBars, faBook, faCircleDot, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import './index.scss';
import { Feature } from 'ol';
import { isEqual, sortBy } from 'lodash';
import { GwmApi, GwmPublicApi } from 'general/api';
import PiezometerData from 'models/PiezometerData';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

const TITLE_OPTIONS: [string, string][] = [
    ['boreholeCode', 'Alias'], 
    ['_', 'TAUW id'], 
    ['broId', 'BRO id'], 
    ['serialNo', 'Serienummer Logger']
];

interface PiezometerFactsheetProps extends WidgetProps {
    lite: boolean;
}

interface PiezometerFactsheetState {
    selection: Feature[];
    active?: Feature;
    titleField: string;
    data?: PiezometerData;
    loading: boolean;
    mapNode?: HTMLDivElement;
}

class PiezometerFactsheet extends Widget<PiezometerFactsheetProps, PiezometerFactsheetState> {
    static defaultProps = {
        title: (): string => app.translator.translate('Tauw Grondwater Factsheet'),
        //description: (): string => app.translator.translate('Lorem ipsum dolor sit amet, consectetur adipiscing elit.'),
        actionLabel: (): string => app.translator.translate('Tauw Grondwater Factsheet'),
        icon: faBook,
        lite: false
    };

    constructor(props: Readonly<PiezometerFactsheetProps>) {
        super(props);

        this.state = {
            selection: [],
            loading: false,
            titleField: 'boreholeCode'
        };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onTitleFieldChanged = this.onTitleFieldChanged.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount?.();

        app.onSelectionChanged = this.onSelectionChanged;

        if (app.selection.length) {
            this.setState({
                selection: sortBy([...app.selection].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR'])
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<PiezometerFactsheetProps>, prevState: Readonly<PiezometerFactsheetState>): void {
        if (!isEqual(prevState.selection, this.state.selection)) {
            if (this.state.selection.length > 0 && 
                    !(this.state.active && this.state.selection.includes(this.state.active))) {

                if (!this.state.active) {
                    this.resize?.([860, 480]);
                }

                this.setState({
                    active: this.state.selection[0],
                    data: undefined,
                    mapNode: undefined
                });
            }
        }
        if (prevState.active != this.state.active) {
            this.initialize();
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount?.();

        app.unregister(this.onSelectionChanged);
    }

    onSelectionChanged(features?: Feature[]): void {
        this.setState({
            selection: sortBy([...features ?? []].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR'])
        });
    }

    onTitleFieldChanged(field: string): void {
        this.setState({ titleField: field });
    }

    onAction(feature: Feature): void {
        this.setState({
            active: feature
        });
    }

    async export(...features: Feature[]): Promise<void> {
        const siteid = features[0]?.getProperties()['SITEID'] as number | undefined;
        if (!siteid) return;

        this.setState({ loading: true });

        const measurepoints = features
            .flatMap(feature => feature?.getProperties()['BORINGNR'] as number | string | undefined)
            .filter(v => typeof v == 'number' || typeof v == 'string') as number[] | string[];

        await (!this.props.lite ? GwmApi.exportPiezometerData({
            siteid: siteid,
            boringnrs: measurepoints as number[],
            filename: `${siteid}_${measurepoints.join('-')}`,
            title: this.state.titleField
        }): GwmPublicApi.exportPiezometerData({
            siteid: siteid,
            piezometer: measurepoints as string[],
            filename: `${siteid}_${measurepoints.join('-')}`,
            title: this.state.titleField
        }));

        this.setState({ loading: false });
    }

    async initialize(): Promise<void> {
        const siteid = this.state.active?.getProperties()['SITEID'] as number | undefined;
        const measurepoint = this.state.active?.getProperties()['BORINGNR'] as number | string | undefined;
        if (!siteid || !measurepoint) return;

        this.setState({ loading: true });

        const response = await (!this.props.lite ? GwmApi.getPiezometerData({
            siteid: siteid,
            boringnr: measurepoint as number,
            filternr: 1 // TODO: not handling multiple filters, so possible incorrect representation of the data
        }) : GwmPublicApi.getPiezometerData({
            siteid: siteid,
            piezometer: measurepoint as string
        }));

        this.setState({
            data: response.data,
            loading: false
        });
    }

    renderTable(): JSX.Element | undefined {
        if (!this.state.data) return;

        const data = this.state.data;

        const boringDatum = data.boreholeDate ? moment(data.boreholeDate, 'YYYY-MM-DDTHH:mm:ss') : undefined;
        const installatieDatum = data.installationDate ? moment(data.installationDate, 'YYYY-MM-DDTHH:mm:ss') : undefined;

        return <div className="factsheet">
            <div className="flex">
                <div className="factsheet-col">
                    <table className="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th className="tableheader" colSpan={2}>Locatie peilbuis</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Adres</th>
                                <td id="address">{data.street ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Situatie</th>
                                <td id="situation">{data.surroundings ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>X-coordinaat</th>
                                <td id="xCoor">{data.coordinateX ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Y-coordinaat</th>
                                <td id="yCoor">{data.coordinateY ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>NAP maaiveld (m)</th>
                                <td id="NAP">{data.nap ?? '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th className="tableheader" colSpan={2}>Plaatsing peilbuis</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Datum installatie</th>
                                <td id="installDate">{boringDatum?.format('DD-MM-YYYY') ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>GWS na plaatsing (cm)</th>
                                <td id="GWSafter">{data.gwsPlacement ?? '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th className="tableheader" colSpan={2}>Gegevens datalogger</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Serienummer</th>
                                <td id="serial">{data.serialNo ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Installatiedatum Logger</th>
                                <td id="loggerDate">{installatieDatum?.format('DD-MM-YYYY') ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Tijdstip</th>
                                <td id="loggerTime">{installatieDatum?.format('h:mm:ss') ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>GWS bij installatie (NAP/cm)</th>
                                <td id="GWS">{data.gwsAtInstallation ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Lengte meetbuis (cm)*</th>
                                <td id="length">{data.tubeLength ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Afstand ok sensor tot bk pb (cm)</th>
                                <td id="OKsensor">{data.okSensorBkPb ?? '-'}</td>
                            </tr>
                            </tbody>
                    </table>
                </div>
                <div className="factsheet-col">
                    <table className="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th className="tableheader" colSpan={2}>Dimensionering en materiaal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>BK pb (m t.o.v. NAP)</th>
                                <td id="BKpbNAP">{data.piezometerNapTop ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>OK pb (m t.o.v. NAP)</th>
                                <td id="OKpbNAP">{data.piezometerNapBottom ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Diepte pb (cm)</th>
                                <td id="depth">{data.filterEnd ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Filterlengte (m)</th>
                                <td id="filterlength">{data.filterLength ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Materiaal peilbuis</th>
                                <td id="material">{data.description ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Afwerking peilbuis</th>
                                <td id="finish">{data.finish ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Materiaal afwerking</th>
                                <td id="materialfinish">{data.finishMaterial ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Diameter buiten (mm)</th>
                                <td id="diameterOutside">{data.diameterOutside ?? '-'}</td>
                            </tr>
                            <tr>
                                <th>Diameter binnen (mm)</th>
                                <td id="dimeterInside">{data.diameterInside ?? '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th className="tableheader">Opmerkingen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="comment">{data.comments ?? '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex">
                {data.photos.map((v, i) => (
                    <div key={i} className={`factsheet-col foto${i+1}`}>
                        <table className="table table-condensed table-hover">
                            <thead>
                                <tr>
                                    <th className="tableheader">Foto {i+1}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {this.renderImage(v)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
            <div className="flex">
                <div className="factsheet-col">
                    <table className="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th className="tableheader">Boorbeschrijving</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="boorstaat">
                                    {data.boreReport ? this.renderImage(data.boreReport) : undefined}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;
    }

    renderImage(url: string): JSX.Element {
        return url.toLowerCase().includes('roundme.com/embed') ? (
            <iframe src={url} frameBorder={0} style={{ minHeight: '200px' }}
                allowFullScreen={true} />
        ) : <img className="img-fluid" src={url} />;
    }

    render(): JSX.Element {
        const title = this.state.data 
            ? Object.keys(this.state.data).includes(this.state.titleField) 
                ? this.state.data[this.state.titleField] ?? '-' 
                : `${this.state.data.borehole}-${this.state.data.filter}` 
            : undefined;

        return (
            <div className="piezometer-factsheet-widget h-100 overflow-hidden d-flex flex-column">


                {!this.state.selection?.length || !this.state.active ? (
                    <h2>Selecteer een peilbuis op de kaart...</h2>
                ) : (<>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">

                        <div className="btn-group flex align-items-start w-100">
                            <button id="factsheet-print" className="btn btn-secondary text-nowrap w-100"
                                onClick={() => this.state.active ? this.export(this.state.active) : undefined}>
                                <FontAwesomeIcon icon={faFilePdf} className="mr-2" style={{ color: '#ad0b00' }} />
                                Download PDF
                            </button>
                            <button id="factsheet-print" className="btn btn-secondary text-nowrap"
                                onClick={() => this.export(...this.state.selection)}>
                                <FontAwesomeIcon icon={faFilePdf} className="mr-2" style={{ color: '#ad0b00' }} />
                                <span className="badge badge-primary">{this.state.selection.length}</span>
                            </button>

                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-factsheet-selection" className="w-100">

                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <span className="w-100 text-truncate">
                                        {this.state.active.getProperties()['BORINGCODE'] ?? this.state.active.getProperties()['DESCRIPTION'] ?? this.state.active.getProperties()['BORINGNR'] ?? 'unknown'}
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.selection.map(v => {
                                        const borehole = v.getProperties()['BORINGNR'] as number;
                                        return (
                                            <Dropdown.Item key={`concentration-graph-borehole-${borehole}`} href="#" active={v == this.state.active}
                                                onClick={() => this.setState({ active: v, data: undefined, mapNode: undefined })}>
                                                {v.getProperties()['DESCRIPTION'] ?? v.getProperties()['BORINGCODE'] ?? borehole}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mt-3">
                        <h2 id="title" className="text-truncate">{title}</h2>

                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-factsheet-title">
                                <FontAwesomeIcon icon={faBars} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {TITLE_OPTIONS.map(([key, label]) => (
                                    <Dropdown.Item key={`factsheet-title-item-${key}`} href="#" active={key == this.state.titleField}
                                        onClick={() => this.setState({ titleField: key })}>
                                        {label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {this.state.data ? this.renderTable() : !this.state.loading ? (
                        <div className="alert alert-warning">
                            <strong>Geen data! </strong>
                            <span>Voor deze peilbuis konden geen gegevens worden gevonden!</span>
                        </div>
                    ) : undefined}
                </>)}

                {this.state.loading ? <PanelLoader /> : undefined}
            </div>
        );
    }
}

export default PiezometerFactsheet;