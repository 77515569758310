import { WidgetProps, Widget, PanelLoader } from '@tauw/viewer-base';
import { faCircleDot, faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';
import './index.scss';
import { Feature } from 'ol';
import { isEqual, sortBy } from 'lodash';
import { GwmApi } from 'general/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import MaintenanceCollection from 'models/MaintenanceCollection';
import moment from 'moment';

interface MaintenanceState {
    selection: Feature[];
    active?: Feature;
    data?: MaintenanceCollection;
    loading: boolean;
    mapNode?: HTMLDivElement;
}

class Maintenance extends Widget<WidgetProps, MaintenanceState> {
    static defaultProps = {
        title: (): string => app.translator.translate('Tauw Grondwater Onderhoud'),
        //description: (): string => app.translator.translate('Lorem ipsum dolor sit amet, consectetur adipiscing elit.'),
        actionLabel: (): string => app.translator.translate('Tauw Grondwater Onderhoud'),
        icon: faScrewdriverWrench
    };

    constructor(props: WidgetProps) {
        super(props);

        this.state = {
            selection: [],
            loading: false
        };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount?.();

        app.onSelectionChanged = this.onSelectionChanged;

        if (app.selection.length) {
            this.setState({
                selection: sortBy([...app.selection].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR'])
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<WidgetProps>, prevState: Readonly<MaintenanceState>): void {
        if (!isEqual(prevState.selection, this.state.selection)) {
            if (this.state.selection.length > 0 && 
                    !(this.state.active && this.state.selection.includes(this.state.active))) {

                if (!this.state.active) {
                    this.resize?.([860, 480]);
                }

                this.setState({
                    active: this.state.selection[0],
                    data: undefined,
                    mapNode: undefined
                });
            }
        }
        if (prevState.active != this.state.active) {
            this.initialize();
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount?.();

        app.unregister(this.onSelectionChanged);
    }

    onSelectionChanged(features?: Feature[]): void {
        this.setState({
            selection: sortBy([...features ?? []].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR'])
        });
    }

    onAction(feature: Feature): void {
        this.setState({
            active: feature
        });
    }

    async initialize(): Promise<void> {
        const siteid = this.state.active?.getProperties()['SITEID'] as number | undefined;
        const measurepoint = this.state.active?.getProperties()['BORINGNR'] as number | undefined;
        if (!siteid || !measurepoint) return;

        this.setState({ loading: true });

        const response = await GwmApi.getMaintenance({
            siteid: siteid,
            boringnr: measurepoint,
            filternr: 1 // TODO: not handling multiple filters, so possible incorrect representation of the data
        });

        this.setState({
            data: response.data,
            loading: false
        });
    }

    render(): JSX.Element {
        return (
            <div className="maintenance-widget h-100 overflow-hidden d-flex flex-column">


                {!this.state.selection?.length || !this.state.active ? (
                    <h2>Selecteer een peilbuis op de kaart...</h2>
                ) : (<>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">

                        <div className="btn-group flex align-items-start w-100">
                            <Dropdown className="w-100">
                                <Dropdown.Toggle variant="primary" id="dropdown-factsheet-selection" className="w-100">

                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <span className="w-100 text-truncate">
                                        {this.state.active.getProperties()['BORINGCODE'] ?? this.state.active.getProperties()['BORINGNR'] ?? 'unknown'}
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.selection.map(v => {
                                        const borehole = v.getProperties()['BORINGNR'] as number;
                                        return (
                                            <Dropdown.Item key={`concentration-graph-borehole-${borehole}`} href="#" active={v == this.state.active}
                                                onClick={() => this.setState({ active: v, data: undefined, mapNode: undefined })}>
                                                {v.getProperties()['BORINGCODE'] ?? borehole}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    {this.state.data ? (<div className="mt-3">
                        <h2>Dataloggers</h2>
                        <table className="table table-borderless table-hover table-sm">
                            <thead className="bg-light-cyan text-light">
                                <tr>
                                    <th scope="col">Serienr</th>
                                    <th scope="col">Start</th>
                                    <th scope="col">Eind</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.dataLoggers.map(x => (
                                    <tr key={moment(x.start).unix()}>
                                        <td scope="row">{x.serial}</td>
                                        <td>{moment(x.start).format('L LT')}</td>
                                        <td>{x.end ? moment(x.end).format('L LT') : 'actief'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <h2>Onderhoud Peilbuis</h2>
                        <table className="table table-borderless table-hover table-sm">
                            <thead className="bg-light-cyan text-light">
                                <tr>
                                    <th scope="col">Datum</th>
                                    <th scope="col">Omschrijving</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.maintenance.map(x => (
                                    <tr key={moment(x.date).unix()}>
                                        <td scope="row">{moment(x.date).format('L LT')}</td>
                                        <td>{x.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>) : !this.state.loading ? (
                        <div className="alert alert-warning">
                            <strong>Geen data! </strong>
                            <span>Voor deze peilbuis konden geen gegevens worden gevonden!</span>
                        </div>
                    ) : undefined}
                </>)}

                {this.state.loading ? <PanelLoader /> : undefined}
            </div>
        );
    }
}

export default Maintenance;