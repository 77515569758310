import { faMemo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GraphAnnotation from 'models/GraphAnnotation';
import { PureComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface AnnotationLabelProps {
    viewBox: {
        x: number;
        y: number;
    },
    value: GraphAnnotation,
    onAnnotationLabelClicked: (value: GraphAnnotation) => void;
}

class AnnotationLabel extends PureComponent<AnnotationLabelProps> {
    constructor(props: Readonly<AnnotationLabelProps>) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.stopBubbling = this.stopBubbling.bind(this);
    }

    stopBubbling(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    onClick() {
        this.props.onAnnotationLabelClicked?.(this.props.value);
    }

    render() {
        const { viewBox, value } = this.props;
        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 0, hide: 250 }}
                overlay={<Tooltip id={`tooltip-annotation-${value.id}`} className="annotation-tooltip">{value.annotation}</Tooltip>}
            >
                <g transform={`translate(${viewBox.x - 16},${viewBox.y - 14})`} onMouseDown={this.stopBubbling} onClick={this.onClick}>
                    <FontAwesomeIcon className={'annotation-icon'} width={14} height={14} icon={faMemo} />
                </g>
            </OverlayTrigger>
        );
    }
}

export default AnnotationLabel;