import React, { Component, createRef, RefObject } from 'react';
import { isEqual, range, sortBy, sortedUniqBy, uniq } from 'lodash';
import moment from 'moment';
import { ChartItem, LegendValue } from '../models/ChartValue';
import { ComposedChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, ReferenceArea, Legend, Label, Tooltip, ReferenceLine } from 'recharts';
import Meetpunt from 'models/Meetpunt';
import TooltipWrapper from './TooltipWrapper';
import LegendWrapper from './LegendWrapper';
import { DateFilter } from './DateFilter';
import { AssessmentTickInfo } from 'models/Assessment';
import { WeatherItem } from '../models/WeatherItem';
import { wrap, releaseProxy, Remote } from 'comlink';
import GraphWorker from 'workers/GraphWorker';
import Assessment from '../../../models/Assessment';
import { AxisDomain } from 'recharts/types/util/types';
import AssessmentAxisTick from './AssessmentAxisTick';
import GraphAnnotation from 'models/GraphAnnotation';
import { AnnotationTickInfo } from '../../../models/GraphAnnotation';
import AnnotationLabel from './AnnotationLabel';
import { COLORS } from '../utils/constants';

interface GraphProps {
    unit: 'mv' | 'nap';
    info: Meetpunt[];
    data: ChartItem[];
    weather?: WeatherItem[];
    assessments?: Assessment[];
    annotations?: GraphAnnotation[];
    graphMode: 'individual' | 'combined';
    canEditAssessments: boolean;
    liteMode: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartRef?: React.RefObject<any>;

    onEditAssessmentClicked?: (assessment: AssessmentTickInfo) => void;
    onAnnotationLabelClicked?: (annotation: GraphAnnotation) => void;
}

interface GraphState {
    data?: ChartItem[];
    weather?: WeatherItem[];
    tooltip?: JSX.Element;
    limits?: [number, number, number, number],
    categories: string[];

    dataMin?: number;
    dataMax?: number;
    dataGhg?: number;
    dataGlg?: number;
    dataRhg?: number;
    dataRlg?: number;
    dataNap?: number;

    refAreaLeft?: number;
    refAreaRight?: number;

    visibility: Record<string, boolean>;

    zoomed: boolean;

    // filter
    assessments: boolean;
    annotations: boolean;
}

export class Graph extends Component<GraphProps, GraphState> {
    constructor(props: Readonly<GraphProps>) {
        super(props);

        this.state = {
            refAreaLeft: undefined,
            refAreaRight: undefined,
            zoomed: false,
            visibility: {},
            assessments: true,
            annotations: true,
            categories: this.getCategories(props.data)
        };

        this.tooltipRef = createRef();

        this.zoom = this.zoom.bind(this);
        this.reset = this.reset.bind(this);
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
        this.onDateRangeReset = this.onDateRangeReset.bind(this);
        this.onGraphZoomAction = this.onGraphZoomAction.bind(this);
        this.onEditAssessmentClicked = this.onEditAssessmentClicked.bind(this);
        this.onAnnotationLabelClicked = this.onAnnotationLabelClicked.bind(this);


    }

    readonly tooltipRef: RefObject<HTMLDivElement>;

    private zoomIntervalHandle?: number;
    private refAreaRight?: number;
    private worker?: Worker;
    private adapter?: Remote<GraphWorker>;

    getCategories(data: ChartItem[]): string[] {
        const main = ['valid', 'released', 'raw', 'manual'];
        if (!this.props.liteMode) return main;

        const available = uniq(data.flatMap(x => x.values).flatMap(x => Object.keys(x).filter(k => typeof x[k] == 'number')));
        return ['valid', 'released', 'raw', 'manual'].filter(x => available.includes(x));
    }

    componentDidMount(): void {
        this.worker = new Worker(`${location.pathname}workers/GraphWorker.js`, { type: 'module' });
        this.adapter = wrap<GraphWorker>(this.worker);

        this.adapter.setData(this.props.data).then(() => {
            const sample = this.props.data[0];
            this.setState({
                dataMin: sample.min,
                dataMax: sample.max,
                dataGhg: sample.ghg,
                dataGlg: sample.glg,
                dataRhg: sample.rhg,
                dataRlg: sample.rlg,
                dataNap: sample.nap,
                visibility: this.getDefaultVisibility()
            }, () => this.reset());
        });
        this.adapter.setWeather(this.props.weather ?? []);
    }

    componentDidUpdate(prevProps: Readonly<GraphProps>, prevState: Readonly<GraphState>): void {
        if (this.state.refAreaLeft != prevState.refAreaLeft) {
            if (this.state.refAreaLeft) {
                this.zoomIntervalHandle = setInterval(() => {
                    if (this.state.refAreaRight != this.refAreaRight) {
                        this.setState({ refAreaRight: this.refAreaRight });
                    }
                }, 100);

            } else {
                clearInterval(this.zoomIntervalHandle);
                this.zoomIntervalHandle = undefined;
                this.refAreaRight = undefined;
            }
        } else {
            if (!isEqual(this.props.data, prevProps.data)) {
                const categories = this.getCategories(this.props.data);
                this.setState({ data: [], categories }, () => {
                    this.adapter?.setData(this.props.data).then(() => {
                        const sample = this.props.data[0];
                        const visibility = this.getDefaultVisibility();
                        for (const key of Object.keys(this.state.visibility)) {
                            if (!Object.keys(visibility).includes(key)) continue;

                            if (key.endsWith('valid')) {
                                visibility[key] = true;
                            } else {
                                visibility[key] = this.state.visibility[key];
                            }
                        }

                        this.setState({
                            dataMin: sample.min,
                            dataMax: sample.max,
                            dataGhg: sample.ghg,
                            dataGlg: sample.glg,
                            dataRhg: sample.rhg,
                            dataRlg: sample.rlg,
                            dataNap: sample.nap,
                            visibility: visibility
                        }, () => this.reset());
                    });
                });
            }
            if (!isEqual(this.props.weather, prevProps.weather)) {
                this.setState({ weather: undefined }, async () => {
                    await this.adapter?.setWeather(this.props.weather ?? []);
                    const weather = await this.adapter?.sampleWeatherData();
                    this.setState({ weather: weather });
                });
            }
        }
    }

    componentWillUnmount(): void {
        if (this.zoomIntervalHandle != undefined) {
            clearInterval(this.zoomIntervalHandle);
            this.zoomIntervalHandle = undefined;
        }

        this.adapter?.[releaseProxy]?.();
        this.worker?.terminate();
    }

    getDefaultVisibility(): Record<string, boolean> {
        return Object.assign({}, ...[
            ...range(0, this.props.data[0].values.length)
                .map(id => `values[${id}]`)
                .flatMap(id => this.state.categories.map(v => `${id}.${v}`)),
            'ghg', 'glg', 'max', 'min', 'nap'
        ].map(v => ({ [v]: v.endsWith('valid') })));
    }

    async zoom(min: number, max: number): Promise<void> {
        if (!this.adapter) return;

        let keys = this.props.data.map(v => v.time);
        let startIndex = keys.indexOf(min);
        if (startIndex == -1) {
            startIndex = keys.indexOf(sortBy(keys, v => Math.abs(v - min))[0]);
        }
        let endIndex = keys.indexOf(max);
        if (endIndex == -1) {
            endIndex = keys.indexOf(sortBy(keys, v => Math.abs(v - max))[0]);
        }

        // Graph can only be drawn for 2 or more data points 
        if (this.props.data.slice(startIndex, endIndex).length > 1) {
            await this.adapter.setSlice([startIndex, endIndex]);
    
            const data = await this.adapter.sampleData();
    
            let weather: WeatherItem[] | undefined;
            if (this.props.weather) {
                keys = this.props.weather.map(v => v.time);
                startIndex = keys.indexOf(min);
                if (startIndex == -1) {
                    startIndex = keys.indexOf(sortBy(keys, v => Math.abs(v - min))[0]);
                }
                endIndex = keys.indexOf(max);
                if (endIndex == -1) {
                    endIndex = keys.indexOf(sortBy(keys, v => Math.abs(v - max))[0]);
                }
    
                await this.adapter.setWeatherSlice([startIndex, endIndex]);
    
                weather = await this.adapter.sampleWeatherData();
            }
    
            const limits = await this.adapter.getLimits(this.state.visibility);

            this.setState({
                data: data,
                weather: weather,
                limits: limits,
                refAreaLeft: undefined,
                refAreaRight: undefined,
                zoomed: true
            });
        } else {
            this.setState({
                refAreaLeft: undefined,
                refAreaRight: undefined
            });
        }
    }

    async reset(): Promise<void> {
        if (!this.adapter) return;

        await this.adapter.setSlice([undefined, undefined]);
        await this.adapter.setWeatherSlice([undefined, undefined]);

        const data = await this.adapter.sampleData();
        let weather: WeatherItem[] | undefined;
        if (this.props.weather) {
            weather = await this.adapter.sampleWeatherData();
        }

        const limits = await this.adapter.getLimits(this.state.visibility);

        this.setState({
            data: data,
            weather: weather,
            limits: limits,
            refAreaLeft: undefined,
            refAreaRight: undefined,
            zoomed: false
        });
    }

    getTickInfo(): { format: string; ticks: number[]; } {
        let format = 'DD MMM YYYY HH:mm';
        const ticks: number[] = [];

        if (this.state.limits) {
            //const start = moment(this.state.limits[0]);
            const range = (this.state.limits[2] - this.state.limits[0]);
            if (range / 31536000000 > 3) { // year
                format = 'YYYY';
            } else if (range / 2592000000 > 6) { // month
                format = 'MMM YYYY';
            } else if (range / 86400000 > 14) { // day
                format = 'DD MMM';
            } else if (range / 86400000 > 1) { // day
                format = 'ddd HH:00';
            } else {
                format = 'HH:mm';
            }

            const count = 8;
            const interval = Math.round(range / (count - 1));

            for (let i = 0; i < count; i++) {
                ticks.push(this.state.limits[0] + interval * i);
            }

            const uniqTicks = sortedUniqBy(ticks, v => moment(v).format(format));
            if (uniqTicks.length !== ticks.length) {
                const count = uniqTicks.length;
                const interval = Math.round(range / (count - 1));

                ticks.splice(0, ticks.length);
                for (let i = 0; i < count; i++) {
                    ticks.push(this.state.limits[0] + interval * i);
                }
            }
        }

        return {
            format: format,
            ticks: ticks //sortedUniqBy(ticks, v => moment(v).format(format))
        };
    }

    getAssessmentsTickInfo(): { tickValues: Record<number, AssessmentTickInfo>; ticks: number[]; } | undefined {
        if (this.props.graphMode !== 'individual') return;

        const minLimit = this.state.limits?.[0];
        const maxLimit = this.state.limits?.[2];

        const ticks: number[] = [];
        const tickValues: Record<number, AssessmentTickInfo> = {};

        const addTickValue = (start: number, end: number, bro?: number, assessment?: Assessment) => {
            // Calculate the tick based on the center for two dates
            const tick = (start + end) / 2;
            ticks.push(tick);
            if (assessment)
                tickValues[tick] = assessment;
            else
                tickValues[tick] = {
                    begindatum: moment(start).toDate(),
                    einddatum: moment(end).toDate(),
                    aangeleverdBro: bro ? moment(bro).toDate(): undefined,
                    mateBeoordeling: 0
                };
        };

        if (this.props.assessments?.length) {
            const ranges: [start: number, end: number][] = [];

            for (let i = 0; i < this.props.assessments.length; i++) {
                const assessment = this.props.assessments[i];
                let startDate = moment(assessment.begindatum).valueOf();
                let endDate = moment(assessment.einddatum).valueOf();
                const broDate = assessment.aangeleverdBro ? moment(assessment.aangeleverdBro).valueOf() : undefined;

                // Skip assessments which are not in the current scope.
                if (startDate >= (maxLimit ?? 0) || endDate <= (minLimit ?? 0)) continue;

                if (minLimit && startDate < minLimit)
                    startDate = minLimit;
                if (maxLimit && endDate > maxLimit)
                    endDate = maxLimit;

                addTickValue(startDate, endDate, broDate, assessment);
                ranges.push([startDate, endDate]);
            }

            // Find the holes (Niet beoordeelde observaties)
            if (ranges.length) {
                if (minLimit && minLimit < ranges[0][0]) {
                    addTickValue(minLimit, ranges[0][0]);
                }
                if (maxLimit && maxLimit > ranges[ranges.length - 1][1]) {
                    addTickValue(ranges[ranges.length - 1][1], maxLimit);
                }
                for (let i = 1; i < ranges.length; i++) {
                    const beginningOfHole = ranges[i - 1][1];
                    const endOfHole = ranges[i][0];
                    if (beginningOfHole < endOfHole) {
                        addTickValue(beginningOfHole, endOfHole);
                    }
                }
            }
        }

        // Add one tick which spans the full width if there are no assessments within range
        if (!ticks.length && minLimit && maxLimit) {
            addTickValue(minLimit, maxLimit);
        }

        return {
            ticks: ticks,
            tickValues: tickValues
        };
    }

    getAnnotationsTickInfo(): AnnotationTickInfo[] | undefined {
        if (this.props.graphMode !== 'individual') return;

        const minLimit = this.state.limits?.[0];
        const maxLimit = this.state.limits?.[2];

        const tickInfo: AnnotationTickInfo[] = [];

        if (this.props.annotations?.length) {

            for (let i = 0; i < this.props.annotations.length; i++) {
                const annotation = this.props.annotations[i];
                let startDate = new Date(annotation.startdate).valueOf();
                let endDate = new Date(annotation.enddate).valueOf();

                // Skip annotations which are not in the current scope.
                if (startDate >= (maxLimit ?? 0) || endDate <= (minLimit ?? 0)) continue;

                const overlapCount = this.props.annotations
                    .slice(0, i)
                    .filter(x => Math.max(new Date(x.startdate).valueOf(), startDate) < Math.min(new Date(x.enddate).valueOf(), endDate)).length;

                const paddingFactor = .075;
                const top = (this.state.limits?.[1] ?? 0);
                const bottom = (this.state.limits?.[3] ?? 0);
                const offset = ((bottom - top) * paddingFactor) * overlapCount;
                const y = this.props.unit === 'nap' ? top + offset : bottom - offset;

                if (minLimit && startDate < minLimit)
                    startDate = minLimit;
                if (maxLimit && endDate > maxLimit)
                    endDate = maxLimit;

                tickInfo.push({
                    x1: startDate,
                    x2: endDate,
                    y: y,
                    value: annotation
                } as AnnotationTickInfo);
            }
        }

        return tickInfo;
    }

    async onToggleVisibility(...values: [key: string, state?: boolean][]): Promise<void> {
        const visibility = { ...this.state.visibility };
        for (const [key, state] of values) {
            visibility[key] = state != undefined ? state : visibility[key] != undefined ? !visibility[key] : false;
        }
        this.setState({ visibility: visibility }, async () => {
            if (this.state.data && this.adapter) {
                const limits = await this.adapter.getLimits(this.state.visibility);
                this.setState({ limits: limits });
            }
        });
    }

    onGraphZoomAction(): void {

        let { refAreaLeft, refAreaRight } = this.state;

        if (refAreaLeft === refAreaRight || refAreaLeft === undefined || refAreaRight === undefined) {
            this.setState(() => ({
                refAreaLeft: undefined,
                refAreaRight: undefined,
            }));
            return;
        }

        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        this.zoom(refAreaLeft, refAreaRight);
    }

    onDateRangeChanged(min: number, max: number): void {
        this.setState({
            refAreaLeft: undefined,
            refAreaRight: undefined,
        });

        this.zoom(min, max);
    }

    onDateRangeReset(): void {
        this.reset();
    }

    onAnnotationLabelClicked(value: GraphAnnotation): void {
        this.props.onAnnotationLabelClicked?.(value);
    }

    onEditAssessmentClicked(value: AssessmentTickInfo): void {
        this.props.onEditAssessmentClicked?.(value);
    }

    getKeys(): { key: string; value: LegendValue; color: string; lineColor?: string; }[] {
        const sample = this.props.data[0];
        const keys = sample.values
            .flatMap((v, i) => this.state.categories.map(k => ({
                key: `values[${i}].${k}`,
                value: {
                    label: app.translator.translate(k, {
                        context: 'GWM',
                        format: 'lc'
                    }),
                    groupId: v.label
                } as LegendValue,
                color: k == 'manual' ? '#f60' : k == 'raw' ? '#a1a1a1' : COLORS[i % COLORS.length],
                lineColor: k == 'released' ? '#f9c5fc' : undefined
            })));
        return keys;
    }

    getLegendNode(keys?: { key: string; value: LegendValue; color: string; lineColor?: string; }[]): React.ReactElement {
        const payload = (keys ?? this.getKeys()).map(k => ({
            id: k.key,
            value: k.value,
            color: k.lineColor ?? k.color
        }));
        return <Legend
            payload={payload}
            content={<LegendWrapper
                visibility={this.state.visibility}
                onClick={(...v) => this.onToggleVisibility(...v.map(x => [x] as [string]))} />}
        />;
    }

    render(): JSX.Element | null {
        const tickInfo = this.getTickInfo();
        const assessmentsTickInfo = this.getAssessmentsTickInfo();
        const annotationsTickInfo = this.getAnnotationsTickInfo();

        const sample = this.props.data[0];
        const keys = this.getKeys();

        const dataPadding = ((this.state.limits?.[3] ?? 0) - (this.state.limits?.[1] ?? 0)) * .1;
        const dataPaddingX = ((this.state.limits?.[2] ?? 0) - (this.state.limits?.[0] ?? 0)) * .1;

        const yAxisDomain: AxisDomain = [
            (typeof this.state.limits?.[1] == 'number' ? this.state.limits[1] - dataPadding : undefined) ?? 'dataMin',
            (typeof this.state.limits?.[3] == 'number' ? this.state.limits[3] + dataPadding : undefined) ?? 'dataMax'
        ];
        const xAxisDomain: AxisDomain = [
            (typeof this.state.limits?.[0] == 'number' ? this.state.limits[0] - dataPaddingX : undefined) ?? 'dataMin',
            (typeof this.state.limits?.[2] == 'number' ? this.state.limits[2] + dataPaddingX : undefined) ?? 'dataMax'
        ];

        const visible = keys.filter(k => this.state.visibility[k.key] && this.state.visibility[k.value.groupId] != false);

        const assessmentLines = this.props.graphMode === 'individual' && this.state.assessments && !this.props.liteMode ? [...new Set(this.props.assessments?.flatMap(o => [o.begindatum, o.einddatum].map(x => moment(x).valueOf())))] : [];
        const refLines = ['max', 'min', 'ghg', 'glg', 'rhg', 'rlg', 'nap']
            .filter(k => this.state.visibility[k]);

        const legendNode = this.getLegendNode(keys);

        return (<>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex flex-column w-100 overflow-auto">
                    <DateFilter
                        dateRangeMin={this.props.data[0].time}
                        dateRangeMax={this.props.data[this.props.data.length - 1].time}
                        current={[
                            this.state.limits?.[0] ?? this.props.data[0].time,
                            this.state.limits?.[2] ?? this.props.data[this.props.data.length - 1].time
                        ]}
                        onDateRangeChanged={this.onDateRangeChanged}
                        onDateRangeReset={this.onDateRangeReset}
                        onClear={this.reset} />
                    
                    <div className="graph user-select-none">
                        {this.state.data ? (
                            <>
                                <ResponsiveContainer width="100%" height={350} debounce={200}>
                                    <ComposedChart data={this.state.data} margin={{ top: 5, left: 0, right: 5, bottom: 15 }}
                                        ref={this.props.chartRef}
                                        onMouseDown={(e) => {
                                            const activeLabel = Number(e?.activeLabel);
                                            if (activeLabel) {
                                                this.setState({
                                                    refAreaLeft: activeLabel,
                                                    refAreaRight: activeLabel
                                                });
                                            }
                                        }}
                                        onMouseMove={this.state.refAreaLeft ? (e) => {
                                            const activeLabel = Number(e?.activeLabel);
                                            if (activeLabel) {
                                                this.refAreaRight = activeLabel;
                                            } else {
                                                this.setState({
                                                    refAreaLeft: undefined,
                                                    refAreaRight: undefined
                                                });
                                            }
                                        } : undefined}
                                        onMouseUp={this.state.refAreaLeft ? this.onGraphZoomAction : undefined}
                                    >

                                        <CartesianGrid strokeDasharray="3 3" />

                                        <XAxis dataKey="time" allowDataOverflow
                                            domain={xAxisDomain}
                                            scale="time"
                                            type="number"
                                            ticks={tickInfo.ticks}
                                            tickFormatter={(v) => moment(v).format(tickInfo.format)}
                                            interval={0} allowDecimals={false}
                                        >
                                            <Label position='centerBottom' style={{ textAnchor: 'middle' }} dy={15}
                                                value="Datum" />
                                        </XAxis>

                                        {this.props.graphMode === 'individual' && this.state.assessments && !this.props.liteMode && <XAxis dataKey="time" allowDataOverflow
                                            domain={xAxisDomain}
                                            scale="time"
                                            type="number"
                                            xAxisId="assessments"
                                            axisLine={false}
                                            tickLine={false}
                                            orientation="top"
                                            ticks={assessmentsTickInfo?.ticks}
                                            tick={(props) => <AssessmentAxisTick x={props.x} y={props.y}
                                                value={props.payload.value}
                                                tickValues={assessmentsTickInfo?.tickValues}
                                                canEditAssessments={this.props.canEditAssessments}
                                                onEditAssessmentClicked={this.onEditAssessmentClicked} />}
                                            interval={0} allowDecimals={false}
                                        >
                                        </XAxis>}

                                        <YAxis orientation="left"
                                            domain={yAxisDomain}
                                            scale="linear" reversed={this.props.unit == 'mv'} allowDataOverflow>
                                            <Label angle={-90} position='insideLeft' style={{ textAnchor: 'middle' }}
                                                value={`Grondwaterstand (${this.props.unit == 'mv' ? 'cm-mv' : 'cm NAP'})`} />
                                        </YAxis>

                                        {refLines.length ? (
                                            <YAxis yAxisId="yAxisRight" orientation="right"
                                                domain={yAxisDomain} interval={0}
                                                scale="linear" reversed={this.props.unit == 'mv'}
                                                ticks={refLines.map(k => sample[k] as number)}
                                                tickFormatter={(v) => refLines.filter(k => sample[k] == v)[0]} />
                                        ) : undefined}

                                        <Tooltip content={<TooltipWrapper container={this.tooltipRef.current ?? undefined}
                                            labels={Object.assign({}, ...keys.map(k => ({ [k.key]: `${k.value.groupId}: ${k.value.label}` })))}
                                            dataMax={this.state.dataMax}
                                            dataMin={this.state.dataMin}
                                            ghg={this.state.dataGhg}
                                            glg={this.state.dataGlg}
                                            rhg={this.state.dataRhg}
                                            rlg={this.state.dataRlg}
                                            nap={this.state.dataNap}
                                            onDataMaxToggled={v => this.onToggleVisibility(['max', v])}
                                            onDataMinToggled={v => this.onToggleVisibility(['min', v])}
                                            onGhgToggled={v => this.onToggleVisibility(['ghg', v])}
                                            onGlgToggled={v => this.onToggleVisibility(['glg', v])}
                                            onRhgToggled={v => this.onToggleVisibility(['rhg', v])}
                                            onRlgToggled={v => this.onToggleVisibility(['rlg', v])}
                                            onNapToggled={v => this.onToggleVisibility(['nap', v])}
                                        />} />

                                        {visible.filter(k => !k.key.endsWith('manual')).reverse().map(k => (
                                            <Line key={`chart-line-${k.key}`} type="natural" dataKey={k.key} fill={k.color} stroke={k.lineColor ?? k.color} strokeWidth={k.lineColor ? 10 : undefined} connectNulls={false}
                                                dot={{ r: 1 }} activeDot={{ r: 8 }} />
                                        ))}

                                        {visible.filter(k => k.key.endsWith('manual')).reverse().map(k => (
                                            <Line key={`chart-line-${k.key}`} type="basis" dataKey={k.key} fill={k.color} stroke={k.color} connectNulls={false}
                                                dot={{ r: 4 }} activeDot={{ r: 8 }} width={0} />
                                        ))}

                                        {refLines.map(k =>
                                            <Line key={k} type="natural" stroke="#000" dataKey={k} yAxisId="yAxisRight" dot={{ r: 0 }} activeDot={{ r: 4 }} />)}

                                        {this.props.graphMode === 'individual' && !this.props.liteMode &&
                                            <>
                                                {this.state.assessments && assessmentLines.map(x =>
                                                    <ReferenceLine key={x} x={x} stroke="#000" strokeDasharray="3 3" />)}
                                                {this.state.annotations && annotationsTickInfo?.map((tickInfo, i) =>
                                                    <ReferenceLine key={i} stroke="#000" strokeDasharray="3 3"
                                                        segment={[{ x: tickInfo.x1, y: tickInfo.y }, { x: tickInfo.x2, y: tickInfo.y }]}
                                                        label={props => <AnnotationLabel value={tickInfo.value} {...props} onAnnotationLabelClicked={this.onAnnotationLabelClicked} />} />)}
                                            </>
                                        }
                                        {this.state.refAreaLeft ? (
                                            <ReferenceArea x1={this.state.refAreaLeft} x2={this.state.refAreaRight} strokeOpacity={0.3} />
                                        ) : undefined}
                                    </ComposedChart>
                                </ResponsiveContainer>

                                {this.props.weather?.length ? <div className="h-100" style={{ maxHeight: '100px', marginRight: refLines.length ? '60px' : undefined }}>
                                    <ResponsiveContainer width="100%" debounce={200}>
                                        <ComposedChart data={this.state.weather} margin={{ top: 5, left: 0, right: 5, bottom: 15 }}>

                                            <CartesianGrid strokeDasharray="3 3" />

                                            <XAxis dataKey="time"
                                                domain={xAxisDomain}
                                                scale="time"
                                                type="number"
                                                tick={false} allowDecimals={false} allowDataOverflow
                                            />

                                            <YAxis scale="linear"
                                                tickFormatter={(v) => `${Math.round(v)}mm`} allowDataOverflow>
                                                <Label angle={-90} position='insideLeft' style={{ textAnchor: 'middle' }}
                                                    value={'uursom'} />
                                            </YAxis>

                                            <Line type="linear" stroke="#000" dataKey="value" dot={{ r: 0 }} activeDot={{ r: 4 }} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div> : undefined}

                                <div className="d-flex flex-column w-100 px-3">
                                    {legendNode}
                                </div>
                            </>
                        ) : undefined}
                    </div>
                </div>

                <div className="chart-info-panel d-flex flex-column flex-shrink-0 overflow-auto pl-3 border-left">
                    <div className="mt-3" ref={this.tooltipRef} />
                </div>
            </div >
        </>);
    }

}

export default Graph;