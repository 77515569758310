
import jsPDF from 'jspdf';
import styles from '!!raw-loader!./export.css'; 

export async function svgToPNG(svg: SVGElement, width: number, height: number): Promise<string | undefined> {
    const canvas = createCanvas(width, height);
    if (!canvas) return;

    const style = document.createElement('style');
    style.innerHTML = styles.replaceAll('\\n', '');

    const defs = svg.getElementsByTagName('defs');
    defs[0].appendChild(style);

    const xml = new XMLSerializer().serializeToString(svg);
    const dataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(xml)}`;

    const imageData = await new Promise<string | undefined>((resolve) => {
        const img = new Image(width, height);
        img.onload = () => {
            canvas.context.drawImage(img, 0, 0);
            const imageData = canvas.element.toDataURL('image/png', 1.0);
            resolve(imageData);
        };
        img.onerror = () => resolve(undefined);
        img.src = dataUrl;
    });

    defs[0].removeChild(style);

    return imageData;
}

function createCanvas(width: number, height: number): { element: HTMLCanvasElement; context: CanvasRenderingContext2D; } | undefined {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set background to white
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, width, height);

    return { element: canvas, context: ctx };
}

export async function svgToPDF(svg: SVGElement, title: string, width: number, height: number): Promise<string | undefined> {
    const imageData = await svgToPNG(svg, width, height);
    if (!imageData) return;

    const pdf = new jsPDF({
        unit: 'px',
        orientation: 'l',
        format: [width, height],
        hotfixes: ['px_scaling']
    });

    pdf.addImage(imageData, 'PNG', 0, 0, width, height);
    pdf.save(title);
}
