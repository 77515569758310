
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MateBeoordeling } from '../utils/constants';
import { faLeftLong, faRightLong } from '@fortawesome/pro-regular-svg-icons';
import { AssessmentTickInfo } from 'models/Assessment';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons';

function AssessmentAxisTick(props: {
    tickValues?: Record<number, AssessmentTickInfo>; 
    canEditAssessments: boolean;
    x: number;
    y: number;
    value: number;
    onEditAssessmentClicked?: (value: AssessmentTickInfo) => void;
}): JSX.Element {
    const info = props.tickValues?.[props.value];

    return (
        <foreignObject x={props.x - 65} y={props.y - 15} width={1} height={1} overflow={'visible'}>
            <div className={`assessment-tick-info${props.canEditAssessments ? ' btn btn-sm p-0' : ''}`} 
                onClick={info && props.canEditAssessments ? () => props.onEditAssessmentClicked?.(info) : undefined}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                xmlns="http://www.w3.org/1999/xhtml">
                <FontAwesomeIcon icon={faLeftLong} width={14} height={14} />
                &nbsp;<span>{
                    app.translator.translate(Object.values(MateBeoordeling)[info?.mateBeoordeling ?? 'Geen'])
                }</span>&nbsp;
                {info?.aangeleverdBro ? (<span className="text-success">
                    <FontAwesomeIcon icon={faCloudArrowUp} width={14} height={14} />&nbsp;
                </span>) : undefined}
                <FontAwesomeIcon icon={faRightLong} width={14} height={14} />
            </div>
        </foreignObject>
    );
}

export default AssessmentAxisTick;