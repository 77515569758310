import { Component } from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { Surface, Symbols } from 'recharts';
import { LegendValue } from '../models/ChartValue';
import { groupBy } from 'lodash';
interface LegendWrapperProps {
    payload: Payload[];
    visibility: Record<string, boolean>;
    simple?: boolean;
    onClick?: (...name: string[]) => void;
}

export class LegendWrapper extends Component<LegendWrapperProps> {
    static defaultProps = {
        payload: []
    };

    constructor(props: Readonly<LegendWrapperProps>) {
        super(props);
    }

    render(): JSX.Element {
        const groups = Object.entries(groupBy(this.props.payload, x => (x.value as LegendValue).groupId));
        if (!groups.length) return <></>;
        
        const toggleGroups = groups
            .map(([, items]) => items.map(item => [item.id as string]))
            .reduce((a, b) => a.map((v, i) => [...v, ...b[i]]));
        const colors = groups[0][1].map(item => item.color as string);

        const nodes = (!this.props.simple ? groups : [groups[0]]).map(([groupid, items]) =>
            <div key={groupid} className={`btn-group${!this.props.simple ? '-vertical' : ''} btn-group-sm mx-2`}>
                <button className="btn btn-light text-primary"
                    onClick={() => this.props.onClick?.(groupid)}>

                    Peilbuis {groupid}
                </button>
                {
                    items.map((item) => {
                        const id = item.id as string;
                        const color = item.color as string;
                        const value = item.value as LegendValue;
                        return (
                            <button key={`overlay-${id}`} className="charts-legend-item btn btn-light text-left font-weight-light text-nowrap pl-3"
                                onClick={() => this.props.onClick?.(id)}>

                                <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                                    {(!this.props.visibility[id] || this.props.visibility[groupid] == false) && <Symbols
                                        cx={5}
                                        cy={5}
                                        type="circle"
                                        size={25}
                                        fill="#FFF"
                                    />}
                                </Surface>

                                <span className="pl-1">{value.label}</span>
                            </button>
                        );
                    })
                }
            </div>
        );

        return (
            <div className="charts-legend">
                {!this.props.simple ? <div className="form-group d-flex flex-column">
                    <label>{app.translator.translate('Selecteer peilbuis')}</label>
                    <div className="btn-group overflow-auto">
                        <div className="btn-group-vertical btn-group-sm">
                            <div className="h-100" />
                            {
                                toggleGroups.map((item, i) => {
                                    const visible = item.every(v => this.props.visibility[v]);
                                    const state = item.some(v => !this.props.visibility[v]);
                                    const toToggle = state ? item.filter(v => !this.props.visibility[v]) : item;

                                    return (
                                        <button key={`legend-row-${i}`} className="charts-legend-item btn btn-light text-left font-weight-light text-nowrap"
                                            onClick={() => this.props.onClick?.(...toToggle)}>

                                            <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                                                <Symbols cx={5} cy={5} type="circle" size={50} fill={colors[i]} />
                                                {!visible && <Symbols
                                                    cx={5}
                                                    cy={5}
                                                    type="circle"
                                                    size={25}
                                                    fill="#FFF"
                                                />}
                                            </Surface>
                                        </button>
                                    );
                                })
                            }
                        </div>
                        {nodes}
                    </div>
                </div> : nodes}
            </div>
        );
    }
}

export default LegendWrapper;