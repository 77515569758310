import { WidgetProps, Widget, PanelLoader, ApiResponse } from '@tauw/viewer-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faCloudRain, faDotCircle, faFileCsv, faFileImage, faFileExcel, faFileExport, faLink, faPlus, faFilePdf, faCommentLines, faToolbox, faArrowLeftToLine } from '@fortawesome/pro-light-svg-icons';
import './index.scss';
import { Feature } from 'ol';
import { get as getProjection, transform } from 'ol/proj';
import { isEqual, mean, sortBy, sortedUniq } from 'lodash';
import { GwmApi, GwmPublicApi } from 'general/api';
import Meetpunt from 'models/Meetpunt';
import Graph from './components/Graph';
import { ChartItem, ChartValue } from './models/ChartValue';
import { gwm_api } from 'protobuf';
import moment from 'moment';
import { WeatherItem } from './models/WeatherItem';
import { getCenter } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import WeatherStation from 'models/WeatherStation';
import { Dropdown } from 'react-bootstrap';
import Assessment, { AssessmentTickInfo } from 'models/Assessment';
import AssessmentEditor from './components/AssessmentEditor';
import GraphAnnotation from 'models/GraphAnnotation';
import AnnotationEditor from './components/AnnotationEditor';
import { createRef, RefObject } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { svgToPDF, svgToPNG } from 'general/svgConverter';
import ValidationEditor from './components/ValidationEditor';

export type RefUnit = 'mv' | 'nap';

interface GroundwaterGraphsProps extends WidgetProps {
    lite: boolean;
    showComments: boolean;
    showGrouping: boolean;
    showRaw: boolean;
    showReleased: boolean;
    showExport: boolean;
}

interface GroundwaterGraphsState {
    selection: Feature[];
    active?: Feature;
    activeSets?: [Meetpunt, gwm_api.SampleCollection][];
    sets?: [Meetpunt, gwm_api.SampleCollection][];
    activeData?: ChartItem[];
    validationSet?: [Meetpunt, gwm_api.SampleCollection];
    stations?: [WeatherStation, number][];
    activeStation?: WeatherStation;
    activeAssessment?: Assessment;
    activeGraphAnnotation?: GraphAnnotation;
    assessmentDateRange?: [Date, Date];
    weather?: WeatherItem[];
    unit: RefUnit;
    displayMode: 'graph' | 'assessment' | 'annotation' | 'validation';
    graphMode: 'individual' | 'combined';
    loading: boolean;
    hasFullAccess: boolean;
}

class GroundwaterGraphs extends Widget<GroundwaterGraphsProps, GroundwaterGraphsState> {
    static defaultProps = {
        title: (): string => app.translator.translate('Tauw Grondwater Grafiek'),
        //info: (): string => app.translator.translate('Lorem ipsum dolor sit amet, consectetur adipiscing elit.'),
        actionLabel: (): string => app.translator.translate('Tauw Grondwater Grafiek'),
        lite: false,
        showRaw: true,
        showReleased: true,
        showComments: true,
        showGrouping: true,
        showExport: true,
        icon: faChartArea
    };

    constructor(props: GroundwaterGraphsProps) {
        super(props);

        this.state = {
            selection: [],
            unit: 'nap',
            graphMode: 'individual',
            loading: false,
            displayMode: 'graph',
            hasFullAccess: false
        };

        this.chartRef = createRef();
        this.graphRef = createRef();

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onGraphEditAssessmentClicked = this.onGraphEditAssessmentClicked.bind(this);
        this.onGraphEditAnnotationClicked = this.onGraphEditAnnotationClicked.bind(this);
        this.return = this.return.bind(this);
        this.updateAssessment = this.updateAssessment.bind(this);
        this.createAssessment = this.createAssessment.bind(this);
        this.createGraphAnnotation = this.createGraphAnnotation.bind(this);
        this.updateGraphAnnotation = this.updateGraphAnnotation.bind(this);
        this.deleteGraphAnnotation = this.deleteGraphAnnotation.bind(this);
        this.exportGraph = this.exportGraph.bind(this);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly chartRef: RefObject<any>;
    readonly graphRef: RefObject<Graph>;

    get api() { return this.props.lite ? GwmPublicApi : GwmApi; }

    componentDidMount(): void {
        super.componentDidMount?.();

        if (!this.props.lite) {
            GwmApi.hasFullAccess().then((result) => {
                if (result.data) {
                    this.setState({
                        hasFullAccess: result.data
                    });
                }
            });
        }

        setTimeout(() => {
            if (!this.state.active && !this.state.selection.length && app.selection.length) {
                this.setState({
                    selection: sortBy([...app.selection].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR'])
                });
            }
        }, 500);

        app.onSelectionChanged = this.onSelectionChanged;
    }

    componentDidUpdate(prevProps: Readonly<GroundwaterGraphsProps>, prevState: Readonly<GroundwaterGraphsState>): void {
        if (!isEqual(prevState.selection, this.state.selection)) {
            if (this.state.selection.length > 0) {
                const active = this.state.active && this.state.selection.includes(this.state.active)
                    ? this.state.active : this.state.selection[0];

                if (this.state.graphMode !== 'individual' || !isEqual(prevState.active, active)) {
                    this.setState({
                        active: active,
                        sets: undefined,
                        activeData: undefined,
                        graphMode: this.state.selection.length == 1 && this.state.graphMode == 'combined' ? 'individual' : this.state.graphMode
                    }, this.state.graphMode !== 'individual' ? () => {
                        this.initialize();
                    } : undefined);
                }

                this.resize?.([860, 600]);
                this.maximize?.();
            }
        } 
        
        if (!isEqual(prevState.active, this.state.active) || prevState.graphMode !== this.state.graphMode || 
            (this.state.displayMode != prevState.displayMode && prevState.displayMode == 'validation')) {

            this.initialize();
        }

        if (prevState.sets != this.state.sets || prevState.unit != this.state.unit) {
            this.refreshActiveData();
        }

        if (this.state.activeStation?.id != prevState.activeStation?.id) {
            this.refreshWeatherData();
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount?.();

        app.unregister(this.onSelectionChanged);
    }

    onSelectionChanged(features?: Feature[]): void {
        this.setState({
            selection: sortBy([...features ?? []].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR'])
        });
    }

    onAction(feature: Feature): void {
        this.setState({
            selection: sortBy([...app.selection].filter(f => f.getProperties()['BORINGNR']), f => f.getProperties()['BORINGNR']),
            active: feature
        });
    }

    async initialize(): Promise<void> {
        if (!this.state.active || !this.state.selection.length) return;

        const dataSets: [Meetpunt, gwm_api.SampleCollection][] = [...(this.state.sets ?? [])];
        const features = this.state.graphMode === 'combined' ?
            [...this.state.selection] : [this.state.active];

        const siteid = features[0]?.getProperties()['SITEID'] as number | undefined;
        if (!siteid) return;

        const measurepoints = features.flatMap(feature => {
            const boringnr = feature.getProperties()['BORINGNR'] as number | string | undefined;
            return boringnr ? [boringnr] : [];
        }).filter(x => dataSets.every(([m]) => m.boringNr != x));
        
        this.setState({ loading: true });

        let response: ApiResponse<Meetpunt[]> | undefined;
        if (measurepoints.length) {
            response = await (this.props.lite ? GwmPublicApi.getInfo({
                siteid: siteid,
                boringnrs: measurepoints as string[]
            }) : GwmApi.getInfo({
                siteid: siteid,
                boringnrs: measurepoints as number[]
            }));
        }

        let stations: [WeatherStation, number][] | undefined;
        if (response?.data?.length) {
            const dataResponses = await Promise.all(response.data.map(async (v) => {
                const r = await (this.props.lite ? GwmPublicApi.getData({
                    siteid: v.siteId,
                    piezometer: v.filterNr as string
                }) : GwmApi.getData({
                    siteid: v.siteId,
                    boringnr: v.boringNr as number,
                    filternr: v.filterNr as number
                }));
                if (!r.data) return;

                // manual should come first in list
                r.data.values = sortBy(r.data.values, x => [x.timestamp, x.manual ? 0 : 1]);
                this.addNullValues(r.data);

                return [v, r.data] as [Meetpunt, gwm_api.SampleCollection];
            }));

            dataSets.push(...dataResponses.filter(v => v) as [Meetpunt, gwm_api.SampleCollection][]);

            const dates = dataSets.flatMap(([m]) => [moment(m.statistics.startDate), moment(m.statistics.endDate)])
                .filter(v => v.isValid());
            const dateRange = [moment.min(dates), moment.max(dates)];

            const stationResponse = await (this.props.lite ? GwmPublicApi : GwmApi).getWeatherStations({
                start: dateRange[0],
                end: dateRange[1],
                site: app.info?.SiteId
            });

            if (stationResponse.data) {
                const epsg4326 = getProjection('EPSG:4326');
                const destProj = app.view?.getProjection() ?? getProjection('EPSG:28992');
                const center = getCenter(this.state.active.getGeometry()?.getExtent() ?? [0, 0, 0, 0]);

                stations = stationResponse.data.map((v) => {
                    const p: Coordinate = transform([v.x, v.y], epsg4326, destProj);
                    const distance = Math.sqrt(Math.pow(center[0] - p[0], 2) + Math.pow(center[1] - p[1], 2));
                    return [v, distance];
                });

                stations = sortBy(stations, ([, v]) => v);
            }
        }

        const boringnr = this.state.active?.getProperties()['BORINGNR'] as number | string | undefined;
        const activeSets = boringnr ? dataSets?.filter(([m]) => m.boringNr == boringnr) : undefined;

        this.setState({
            loading: false,
            sets: dataSets,
            stations: stations ?? this.state.stations,
            activeStation: stations?.[0]?.[0] ?? this.state.activeStation,
            activeSets: activeSets
        });
    }

    addNullValues(data: gwm_api.SampleCollection | gwm_api.WeatherCollection): void {
        const sampleSize = 20;
        if (data.values.length <= sampleSize) return;

        const diffs = data.values.map((v, i, a) =>
            i + 1 < a.length ? a[i + 1].timestamp - v.timestamp : v.timestamp - a[i - 1].timestamp);

        for (let j = data.values.length - 2; j >= 0; j--) {
            const remainder = (diffs.length - j) % sampleSize;
            const subset = diffs.slice(j - (sampleSize - remainder), j + remainder);
            const avg = mean(subset);
            const std = Math.sqrt(mean(subset.map(v => Math.pow(v - avg, 2))));

            if (diffs[j] - avg > std * 2) {
                data.values.splice(j + 1, 0, {
                    timestamp: data.values[j + 1].timestamp - 1
                });
            }
        }
    }

    refreshActiveData(): void {
        this.setState({ loading: true });

        let sortedData: ChartItem[] | undefined;

        let dataSets = this.state.sets ?? [];
        if (this.state.graphMode == 'individual' && this.state.active) {
            const boringnr = this.state.active.getProperties()['BORINGNR'] as number | string | undefined;
            dataSets = dataSets.filter(([m]) => m.boringNr == boringnr);
        }

        if (dataSets?.length) {
            // map all data to a record
            const keys = sortedUniq(sortBy(dataSets.flatMap(([, v]) => v.values.map(x => x.timestamp as number))));

            if (keys.length) {
                const isNap = this.state.unit == 'nap';

                const [min, max] = dataSets.map(([m]) => [
                    isNap ? m.statistics.min : (typeof m.statistics.max == 'number' ? m.napLevel - m.statistics.max : undefined),
                    isNap ? m.statistics.max : (typeof m.statistics.min == 'number' ? m.napLevel - m.statistics.min : undefined)
                ]).reduce(([aMin, aMax], [bMin, bMax]) => [
                    typeof aMin == 'number' && typeof bMin == 'number' ? Math.min(aMin, bMin) : typeof aMin == 'number' ? aMin : bMin,
                    typeof aMax == 'number' && typeof bMax == 'number' ? Math.max(aMax, bMax) : typeof aMax == 'number' ? aMax : bMax
                ]);

                let [nap, ghg, glg, rhg, rlg]: (number | undefined)[] = [];
                if (this.state.graphMode == 'individual') {
                    const info = dataSets[0][0];
                    nap = isNap ? info.napLevel : 0;
                    ghg = typeof info.statistics.ghg == 'number' ? (isNap ? info.statistics.ghg : info.napLevel - info.statistics.ghg) : undefined;
                    glg = typeof info.statistics.glg == 'number' ? (isNap ? info.statistics.glg : info.napLevel - info.statistics.glg) : undefined;
                    rhg = typeof info.statistics.rhg == 'number' ? (isNap ? info.statistics.rhg : info.napLevel - info.statistics.rhg) : undefined;
                    rlg = typeof info.statistics.rlg == 'number' ? (isNap ? info.statistics.rlg : info.napLevel - info.statistics.rlg) : undefined;
                } else if (!isNap) {
                    nap = 0;
                }

                const data: Record<number, ChartItem> = {};
                for (const k of keys) {
                    data[k] = {
                        time: k,
                        values: [],
                        min: min,
                        max: max,
                        ghg: ghg,
                        glg: glg,
                        rhg: rhg,
                        rlg: rlg,
                        nap: nap
                    };
                }

                let j = 0;
                for (const [m, samples] of dataSets) {
                    let i = 0;
                    const label = !this.props.lite ? `${m.boringNr}.${m.filterNr}` : `#${j + 1}${m.broId ? ` [${m.broId}]` : ''}`;

                    if (samples.values.length > 0) {
                        for (; keys[i] < samples.values[0].timestamp; i++) {
                            const item: ChartValue = {
                                label: label,
                                raw: null, valid: null, released: null, manual: null
                            };
                            data[keys[i]].values.push(item);
                        }

                        let manual: number | undefined;
                        for (const sample of samples.values) {
                            let [raw, valid, released]: (number | undefined)[] = [];
                            if (typeof sample.value == 'number') {
                                // manual always come first in list
                                if (sample.manual) {
                                    manual = !isNap ? m.napLevel - sample.value : sample.value;
                                    continue;
                                }

                                raw = !isNap ? m.napLevel - sample.value : sample.value;
                                if (sample.valid) valid = raw;
                                if (sample.released) released = raw;
                            }

                            for (; keys[i] <= sample.timestamp; i++) {
                                const item: ChartValue = {
                                    label: label,
                                    valid: valid ?? null,
                                    raw: this.props.showRaw ? raw ?? null : null,
                                    released: this.props.showReleased ? released ?? null : null,
                                    manual: null
                                };

                                if (manual && sample.timestamp == keys[i]) {
                                    item.manual = manual;
                                    manual = undefined;
                                }

                                data[keys[i]].values.push(item);
                            }
                        }
                    }

                    for (; i < keys.length; i++) {
                        const item: ChartValue = {
                            label: label,
                            raw: null, valid: null, released: null, manual: null
                        };
                        data[keys[i]].values.push(item);
                    }

                    j++;
                }

                sortedData = sortBy(Object.values(data), v => v.time);

                for (const value of sortedData.flatMap(v => v.values)) {
                    value.manual = value.manual ?? null;
                    value.raw = value.raw ?? null;
                    value.valid = value.valid ?? null;
                }
            }
        }

        this.setState({ loading: false, activeData: sortedData });
    }

    async refreshWeatherData(): Promise<void> {

        let data: gwm_api.IWeather[] | undefined;
        if (this.state.activeStation && this.state.sets) {
            const dates = this.state.sets.flatMap(([m]) => [moment(m.statistics.startDate), moment(m.statistics.endDate)])
                .filter(v => v.isValid());
            const dateRange = [moment.min(dates), moment.max(dates)];
            const isMeeth2o = this.state.activeStation.source == 'meeth2o';
            const siteid = app.info?.SiteId;

            if (isMeeth2o && typeof siteid != 'number') {
                throw 'siteid shouldn\'t be null at this point';
            }

            const weatherResponse = await (this.props.lite ? GwmPublicApi.getWeatherData({
                stationOrSite: !isMeeth2o ? Number(this.state.activeStation.id) : siteid as number,
                piezometer: isMeeth2o ? this.state.activeStation.id.toString() : undefined,
                start: dateRange[0],
                end: dateRange[1]
            }) : GwmApi.getWeatherData({
                stationOrSite: !isMeeth2o ? Number(this.state.activeStation.id) : siteid as number,
                boring: isMeeth2o ? Number(this.state.activeStation.id) : undefined,
                start: dateRange[0],
                end: dateRange[1]
            }));

            if (weatherResponse.data) {
                data = weatherResponse.data.values;
            }
        }

        this.setState({
            weather: data?.map(v => ({
                time: v.timestamp as number,
                value: v.precipitation ?? 0
            }))
        });
    }

    refreshGraphAnnotations(data: GraphAnnotation[]): void {
        const set = this.state.activeSets?.[0];
        const boringnr = set?.[0].boringNr;

        if (this.state.sets && boringnr) {
            const index = this.state.sets?.findIndex(x => x[0].boringNr == boringnr) as number;
            this.state.sets[index][0].graphAnnotations = data;

            set[0].graphAnnotations = data;
            this.forceUpdate();
        }
    }

    refreshAssessments(data: Assessment[]): void {
        const set = this.state.activeSets?.[0];
        const boringnr = set?.[0].boringNr;

        if (this.state.sets && boringnr) {
            const index = this.state.sets?.findIndex(x => x[0].boringNr == boringnr) as number;
            this.state.sets[index][0].assessments = data;

            set[0].assessments = data;
            this.forceUpdate();
        }
    }

    async download(format: 'xlsx' | 'csv'): Promise<void> {
        this.setState({ loading: true });

        const features = this.state.graphMode === 'combined' ?
            [...this.state.selection] : [this.state.active];


        const siteid = features[0]?.getProperties()['SITEID'] as number | undefined;
        const measurepoints = features.flatMap(feature => {
            const boringnr = feature?.getProperties()['BORINGNR'] as number | string | undefined;
            return boringnr ? [boringnr] : [];
        });

        if (siteid && measurepoints.length > 0 && this.state.sets) {

            const label = this.state.sets.filter(([m]) => measurepoints.includes(m.boringNr))
                .map(([m]) => !this.props.lite ? `${m.boringNr}-${m.filterNr}`: `${m.broId ?? m.filterNr}`).join('_')
                ;

            await (this.props.lite ? GwmPublicApi.exportData({
                siteid: siteid,
                piezometers: measurepoints as string[],
                filename: `${siteid}_${label}`,
                format: format
            }) : GwmApi.exportData({
                siteid: siteid,
                boringnrs: measurepoints as number[],
                filename: `${siteid}_${label}`,
                format: format
            }));
        }

        this.setState({ loading: false });
    }

    onGraphEditAssessmentClicked(assessment: AssessmentTickInfo): void {
        if (this.state.activeSets?.[0]) {
            const activeAssessment = assessment.guid ? this.state.activeSets[0][0].assessments?.find(x => x.guid == assessment.guid) : undefined;
            const start = typeof assessment.begindatum == 'string' ? moment(assessment.begindatum).toDate() : assessment.begindatum;
            const end = typeof assessment.einddatum == 'string' ? moment(assessment.einddatum).toDate() : assessment.einddatum;

            this.setState({
                displayMode: 'assessment',
                activeAssessment: activeAssessment,
                assessmentDateRange: [start, end]
            });
        }
    }

    onGraphEditAnnotationClicked(graphAnnotation: GraphAnnotation): void {
        if (this.state.activeSets?.[0]) {
            const activeGraphAnnotation = this.state.activeSets[0][0].graphAnnotations?.find(x => x.id == graphAnnotation.id);

            this.setState({
                displayMode: 'annotation',
                activeGraphAnnotation: activeGraphAnnotation,
                //assessmentDateRange: [assessment.begindatum, assessment.einddatum]
            });
        }
    }

    onGraphEditValidationClicked(set: [Meetpunt, gwm_api.SampleCollection]): void {
        this.setState({
            displayMode: 'validation',
            validationSet: set
        });
    }

    return(): void {
        this.setState({
            displayMode: 'graph',
            activeAssessment: undefined,
            assessmentDateRange: undefined,
            activeGraphAnnotation: undefined
        });
    }

    async createAssessment(assessment: Assessment): Promise<void> {
        const measurepoint = this.state.activeSets?.[0]?.[0];
        if (measurepoint) {
            const response = await GwmApi.createAssessment({
                projectnr: measurepoint.projectNr,
                boringnr: measurepoint.boringNr as number,
                filternr: measurepoint.filterNr as number,
                assessment: assessment
            });
            if (response.data) {
                this.refreshAssessments(response.data);
            }
        }

        this.return();
    }

    async updateAssessment(assessment: Assessment): Promise<void> {
        const response = await GwmApi.updateAssessment({
            assessment: assessment
        });
        if (response.data) {
            this.refreshAssessments(response.data);
        }

        this.return();
    }

    async createGraphAnnotation(graphAnnotation: GraphAnnotation): Promise<void> {
        const measurepoint = this.state.activeSets?.[0]?.[0];
        if (measurepoint) {
            const response = await GwmApi.createGraphAnnotation({
                projectnr: measurepoint.projectNr,
                boringnr: measurepoint.boringNr as number,
                filternr: measurepoint.filterNr as number,
                graphAnnotation: graphAnnotation
            });
            if (response.data) {
                this.refreshGraphAnnotations(response.data);
            }
        }

        this.return();
    }

    async updateGraphAnnotation(graphAnnotation: GraphAnnotation): Promise<void> {
        const response = await GwmApi.updateGraphAnnotation({
            graphAnnotation: graphAnnotation
        });
        if (response.data) {
            this.refreshGraphAnnotations(response.data);
        }

        this.return();
    }

    async deleteGraphAnnotation(graphAnnotation: GraphAnnotation): Promise<void> {
        const response = await GwmApi.deleteGraphAnnotation({
            graphAnnotation: graphAnnotation
        });
        if (response.data) {
            this.refreshGraphAnnotations(response.data);
        }

        this.return();
    }

    async refreshOnValidation(): Promise<void> {
        if (this.state.sets && this.state.validationSet) {
            const measurepoint = this.state.validationSet[0];
            const toDelete = this.state.sets.filter(x => x[0].projectNr == measurepoint.projectNr && x[0].boringNr == measurepoint.boringNr);
            for (const item of toDelete) {
                this.state.sets.splice(this.state.sets.indexOf(item), 1);
            }
            await this.initialize();
        }
    }

    async exportGraph(type: 'png' | 'pdf'): Promise<void> {
        const graphNode: SVGElement = this.chartRef.current?.container?.childNodes[0];
        if (!graphNode) return;

        const { width, height } = graphNode.getBoundingClientRect();
        let totalHeight = height;

        const title = `graph_Project ${this.state.sets?.[0][0].projectNr}.${type}`;

        const legendNode: JSX.Element | undefined = this.graphRef?.current?.getLegendNode();
        if (!legendNode) return;

        const legendHeight = 129.25;
        totalHeight += legendHeight;

        const exportNode: SVGElement = graphNode.cloneNode(true) as SVGElement;
        const legend: JSX.Element = <foreignObject width={width} height={legendHeight} x={0} y={height}>
            <body
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                xmlns="http://www.w3.org/1999/xhtml">
                {legendNode}
            </body>
        </foreignObject>;

        exportNode.innerHTML += renderToStaticMarkup(legend);
        exportNode.setAttribute('height', totalHeight.toString());
        exportNode.setAttribute('viewBox', `0 0 ${width} ${totalHeight}`);

        if (type == 'pdf') {
            await svgToPDF(exportNode, title, width, totalHeight);
        }
        else if (type == 'png') {
            const imageData = await svgToPNG(exportNode, width, totalHeight);

            if (imageData) {
                const link = document.createElement('a');
                link.href = imageData;
                link.download = title;
                link.style.display = 'none';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    render(): JSX.Element {
        const hasData = !!this.state.activeData;
        const dateFormat = 'DD MMM YYYY';

        const getAssessmentTitle = (assessment: Assessment): string => {
            const startDate = moment(assessment.begindatum);
            const endDate = moment(assessment.einddatum);
            return `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`;
        };

        const getGraphAnnotationTitle = (annotation: GraphAnnotation): string => {
            const startDate = moment(annotation.startdate);
            const endDate = moment(annotation.enddate);
            return `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`;
        };

        let displayNode;
        if (this.state.selection?.length && this.state.active) {
            switch (this.state.displayMode) {
            case 'graph':
                displayNode = <>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">
                        <div>
                            <div className="btn-group">
                                <button className={`btn ${this.state.unit == 'nap' ? 'btn-dark-cyan' : 'btn-outline-secondary text-dark-cyan'}`} disabled={!hasData} onClick={() => this.setState({ unit: 'nap' })}>
                                    {app.translator.translate('NAP')}
                                </button>
                                <button className={`btn ${this.state.unit == 'mv' ? 'btn-dark-cyan' : 'btn-outline-secondary text-dark-cyan'}`} disabled={!hasData} onClick={() => this.setState({ unit: 'mv' })}>
                                    {app.translator.translate('Maaiveld')}
                                </button>
                            </div>
                            {this.state.stations?.length ? <div className="btn-group ml-3">
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-weather-stations" className="w-100 mr-2 flex-fill text-dark-cyan">

                                        <FontAwesomeIcon icon={faCloudRain} className="mr-2" />
                                        <span className="w-100 text-truncate">
                                            {this.state.activeStation?.name ?? '...'}
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                                        {this.state.stations?.map(([v, d]) => (
                                            <Dropdown.Item key={`graph-${v.id}`} href="#" active={v.id == this.state.activeStation?.id}
                                                className="d-flex flex-row align-items-center justify-content-between"
                                                onClick={() => this.setState({ activeStation: v })}>
                                                {v.name}
                                                <span className="ml-3">
                                                    <span className="badge badge-secondary">{d < 1000 ? `${Math.round(d)} m` : `${Math.round(d / 1000)} km`}</span>
                                                    <span className="badge badge-secondary ml-1">{v.source}</span>
                                                </span>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> : undefined}
                        </div>

                        <div>
                            {this.state.graphMode === 'individual' && !this.props.lite && (
                                <div className="btn-group mr-2">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-comments" className="w-100 mr-2 flex-fill text-dark-cyan">

                                            <FontAwesomeIcon icon={faCommentLines} className="mr-2" />
                                            <span className="w-100 text-truncate">
                                                Opmerkingen
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                                            <Dropdown.Item href="#"
                                                onClick={() => this.setState({ displayMode: 'annotation', activeGraphAnnotation: undefined })}>
                                                <FontAwesomeIcon icon={faPlus} /> Opmerking toevoegen
                                            </Dropdown.Item>

                                            {this.state.activeSets?.[0]?.[0].graphAnnotations?.length ? <>
                                                <Dropdown.Divider />

                                                <Dropdown.Header>Wijzig een opmerking</Dropdown.Header>
                                                
                                                {this.state.activeSets[0][0].graphAnnotations.map(x =>
                                                    <Dropdown.Item key={x.id} href="#"
                                                        onClick={() => this.setState({ displayMode: 'annotation', activeGraphAnnotation: x })}>
                                                        {getGraphAnnotationTitle(x)}
                                                    </Dropdown.Item>
                                                )}
                                            </> : undefined}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )}
                            {this.state.graphMode === 'individual' && this.state.hasFullAccess && !this.props.lite && (<>
                                <div className="btn-group mr-2">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-assesments" className="w-100 mr-2 flex-fill text-dark-cyan">

                                            <FontAwesomeIcon icon={faToolbox} className="mr-2" />
                                            <span className="w-100 text-truncate">
                                                Beheer
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                                            <Dropdown.Item href="#"
                                                onClick={() => this.setState({ displayMode: 'assessment', activeAssessment: undefined })}>
                                                <FontAwesomeIcon icon={faPlus} /> Beoordeling toevoegen
                                            </Dropdown.Item>

                                            {this.state.activeSets?.[0]?.[0].assessments?.length ? <>
                                                <Dropdown.Divider />

                                                <Dropdown.Header>Wijzig een beoordeling</Dropdown.Header>
                                                
                                                {this.state.activeSets[0][0].assessments.map(x =>
                                                    <Dropdown.Item key={x.guid} href="#"
                                                        onClick={() => this.setState({ displayMode: 'assessment', activeAssessment: x })}>
                                                        {getAssessmentTitle(x)}
                                                    </Dropdown.Item>
                                                )}
                                            </> : undefined}
                                            
                                            <Dropdown.Divider />
                                            
                                            <Dropdown.Header>Valideer metingen</Dropdown.Header>

                                            {this.state.activeSets?.map(s => (
                                                <Dropdown.Item key={`validate-${s[0].boringNr}-${s[0].filterNr}`} href="#"
                                                    onClick={() => this.onGraphEditValidationClicked(s)}>
                                                    {`Peilbuis ${s[0].boringNr}.${s[0].filterNr}`}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </>)}
                            <div className="btn-group ml-3">
                                {this.props.showExport ? <div className="btn-group">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-export" className="w-100 mr-2 flex-fill text-dark-cyan" disabled={!hasData}>

                                            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                            <span className="w-100 text-truncate">
                                                Export
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                                            <Dropdown.Header>Data</Dropdown.Header>

                                            <Dropdown.Item href="#" onClick={() => this.download('xlsx')}>
                                                <FontAwesomeIcon icon={faFileExcel} /> Excel <mark>.xlsx</mark>
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => this.download('csv')}>
                                                <FontAwesomeIcon icon={faFileCsv} /> Komma-gescheiden <mark>.csv</mark>
                                            </Dropdown.Item>
                                            
                                            <Dropdown.Divider />
                                            
                                            <Dropdown.Header>Grafiek</Dropdown.Header>
                                            
                                            <Dropdown.Item href="#" onClick={() => this.exportGraph('png')}>
                                                <FontAwesomeIcon icon={faFileImage} /> Afbeelding <mark>.png</mark>
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => this.exportGraph('pdf')}>
                                                <FontAwesomeIcon icon={faFilePdf} /> PDF-bestand <mark>.pdf</mark>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> : undefined}
                                <div className="btn-group">
                                    <Dropdown>
                                        <Dropdown.Toggle variant={this.state.graphMode == 'individual' ? 'primary' : 'secondary'} id="dropdown-selection" 
                                            className={`btn ${this.state.graphMode != 'individual' ? 'text-primary' : ''}`}>

                                            <FontAwesomeIcon icon={faDotCircle} className="mr-2" />
                                            <span className="w-100 text-truncate">
                                                {this.state.active.getProperties()['BORINGCODE'] ?? this.state.active.getProperties()['DESCRIPTION'] ?? this.state.active.getProperties()['BORINGNR'] ?? 'unknown'}
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu alignRight style={{ maxHeight: '400px', overflow: 'auto' }}>
                                            {this.state.selection.map(v => {
                                                const borehole = v.getProperties()['BORINGNR'] as number | string;
                                                return (
                                                    <Dropdown.Item key={`graph-${borehole}`} href="#" active={v == this.state.active}
                                                        onClick={() => this.setState({ active: v, graphMode: 'individual' })}>
                                                        {v.getProperties()['DESCRIPTION'] ?? v.getProperties()['BORINGCODE'] ?? v.getProperties()['BORINGNR']}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                {this.state.selection.length > 1 && this.props.showGrouping ? <div className="btn-group">
                                    <button className={`btn btn-${this.state.graphMode == 'combined' ? 'dark-cyan' : 'secondary'}`}
                                        onClick={() => this.setState({ graphMode: this.state.graphMode == 'combined' ? 'individual' : 'combined' })}>
                                        <FontAwesomeIcon icon={faLink} />
                                    </button>
                                </div> : undefined}
                            </div>
                        </div>
                    </div>

                    {this.state.sets?.length && this.state.activeData ? (
                        <div className="d-flex flex-column h-100 overflow-hidden">
                            <Graph
                                info={this.state.sets.map(([m]) => m)} graphMode={this.state.graphMode}
                                data={this.state.activeData}
                                liteMode={this.props.lite}
                                assessments={!this.props.lite ? this.state.activeSets?.[0]?.[0].assessments : undefined}
                                annotations={this.props.showComments ? this.state.activeSets?.[0]?.[0].graphAnnotations : undefined}
                                canEditAssessments={!this.props.lite && this.state.hasFullAccess}
                                weather={this.state.weather}
                                unit={this.state.unit}
                                onEditAssessmentClicked={!this.props.lite ? this.onGraphEditAssessmentClicked : undefined}
                                onAnnotationLabelClicked={!this.props.lite ? this.onGraphEditAnnotationClicked : undefined}
                                ref={this.graphRef}
                                chartRef={this.chartRef} />
                        </div>
                    ) : !this.state.loading ? (
                        <div className="alert alert-warning">
                            <strong>{app.translator.translate('Geen data!')} </strong>
                            <span>{app.translator.translate('Voor deze peilbuis konden geen gegevens worden gevonden!')}</span>
                        </div>
                    ) : undefined}
                </>;
                break;
            case 'validation':
                displayNode = <>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">
                        <div>
                            <div className="btn-group">
                                <button className={`btn ${this.state.unit == 'nap' ? 'btn-dark-cyan' : 'btn-outline-secondary text-dark-cyan'}`} disabled={!hasData} onClick={async () => {
                                    await this.refreshOnValidation();
                                    this.setState({ unit: 'nap' });
                                }}>
                                    {app.translator.translate('NAP')}
                                </button>
                                <button className={`btn ${this.state.unit == 'mv' ? 'btn-dark-cyan' : 'btn-outline-secondary text-dark-cyan'}`} disabled={!hasData} onClick={async () => {
                                    await this.refreshOnValidation();
                                    this.setState({ unit: 'mv' });
                                }}>
                                    {app.translator.translate('Maaiveld')}
                                </button>
                            </div>
                        </div>

                        <div>
                            <button className="btn btn-primary" onClick={async () => {
                                await this.refreshOnValidation();
                                this.setState({ displayMode: 'graph', validationSet: undefined });
                            }}>
                                <FontAwesomeIcon icon={faArrowLeftToLine} /> {app.translator.translate('Terug')}
                            </button>
                        </div>
                    </div>

                    {this.state.validationSet && this.state.activeData ? (
                        <ValidationEditor 
                            data={this.state.validationSet} 
                            chartData={this.state.activeData}
                            unit={this.state.unit}
                            onLoading={s => this.setState({ loading: s })} />
                    ) : !this.state.loading ? (
                        <div className="alert alert-warning">
                            <strong>{app.translator.translate('Geen data!')} </strong>
                            <span>{app.translator.translate('Voor deze peilbuis konden geen gegevens worden gevonden!')}</span>
                        </div>
                    ) : undefined}
                </>;
                break;
            case 'assessment':
                displayNode =
                        <AssessmentEditor
                            assessment={this.state.activeAssessment}
                            dateRange={this.state.assessmentDateRange}
                            onCancelClicked={this.return}
                            onCreateClicked={this.createAssessment}
                            onUpdateClicked={this.updateAssessment}
                        />;
                break;
            case 'annotation':
                displayNode =
                        <AnnotationEditor
                            graphAnnotation={this.state.activeGraphAnnotation}
                            dateRange={this.state.assessmentDateRange}
                            onCancelClicked={this.return}
                            onCreateClicked={this.createGraphAnnotation}
                            onUpdateClicked={this.updateGraphAnnotation}
                            onDeleteClicked={this.deleteGraphAnnotation}
                        />;
                break;
            }
        }

        return (
            <div className="groundwater-graphs-widget d-flex flex-column h-100 overflow-hidden">
                {!this.state.selection?.length || !this.state.active ? (
                    <h1>{app.translator.translate('Selecteer een peilbuis op de kaart...')}</h1>
                ) : displayNode}

                {this.state.loading ? <PanelLoader /> : undefined}
            </div>
        );
    }
}

export default GroundwaterGraphs;
