import { ApiBase, ApiResponse } from '@tauw/viewer-base';
import Meetpunt from '../../models/Meetpunt';
import PiezometerData from '../../models/PiezometerData';
import config from './config.json';
import { gwm_api } from 'protobuf';
import { Moment } from 'moment';
import WeatherStation from '../../models/WeatherStation';
import Assessment from '../../models/Assessment';
import GraphAnnotation from '../../models/GraphAnnotation';
import MaintenanceCollection from '../../models/MaintenanceCollection';
import PublicSettings from '../../models/PublicSettings';

export class GwmApi extends ApiBase {
    // private static adapter?: Remote<ApiWorker>;

    static configure(basePath: string, accessToken?: string): void {
        super.configure(basePath, accessToken);
    }

    static async getInfo(params: { siteid: number; boringnrs: number[]; }): Promise<ApiResponse<Meetpunt[]>> {
        const response = await super.fetch(config.grondwater.url, `${config.grondwater.actions.info}/${params.siteid}?${params.boringnrs.map(v => `boringnr=${v}`).join('&')}`) as ApiResponse<Meetpunt[]>;
        return response;
    }

    static async getData(params: { siteid: number; boringnr: number; filternr: number; }): Promise<ApiResponse<gwm_api.SampleCollection>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.data, [params.siteid, params.boringnr, params.filternr], undefined, 'blob') as ApiResponse<Blob>;
        const samplesResponse: ApiResponse<gwm_api.SampleCollection> = {
            success: false,
            error: response.error
        };
        if (response.data) {
            const blob = response.data;
            const bytes = new Uint8Array(await blob.arrayBuffer());
            const samples = gwm_api.SampleCollection.decode(bytes);
            samplesResponse.data = samples;
            samplesResponse.success = true;
        }
        return samplesResponse;
    }

    static async getMaintenance(params: { siteid: number; boringnr: number; filternr: number; }): Promise<ApiResponse<MaintenanceCollection>> {
        const response = await super.fetch<MaintenanceCollection>(config.grondwater.url, config.grondwater.actions.maintenance, [params.siteid, params.boringnr, params.filternr]) as ApiResponse<MaintenanceCollection>;
        return response;
    }

    static async getWeatherStations(params: { start: Moment; end: Moment; site?: number }): Promise<ApiResponse<WeatherStation[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.weatherStations, [params.start.toISOString(), params.end.toISOString(), params.site]) as ApiResponse<WeatherStation[]>;
        return response;
    }

    static async getWeatherData(params: { stationOrSite: number; boring?: number; start: Moment; end: Moment; }): Promise<ApiResponse<gwm_api.WeatherCollection>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.weatherData, [params.start.toISOString(), params.end.toISOString(), params.stationOrSite, params.boring], undefined, 'blob') as ApiResponse<Blob>;
        const samplesResponse: ApiResponse<gwm_api.WeatherCollection> = {
            success: false,
            error: response.error
        };
        if (response.data) {
            const blob = response.data;
            const bytes = new Uint8Array(await blob.arrayBuffer());
            const samples = gwm_api.WeatherCollection.decode(bytes);
            samplesResponse.data = samples;
            samplesResponse.success = true;
        }
        return samplesResponse;
    }

    static async exportData(params: { siteid: number; boringnrs: number[]; filename: string; format: 'xlsx' | 'csv'; }): Promise<ApiResponse<[number, number][][][]>> {
        const filename = `${params.filename}.${params.format}`;
        const response = await super.fetchFile(config.grondwater.url, `${config.grondwater.actions.export}/${params.siteid}?${params.boringnrs.map(v => `boringnr=${v}`).join('&')}&format=${params.format}`);
        return response.success ? super.pushFile(response, filename) : response;
    }

    static async getPiezometerData(params: { siteid: number; boringnr: number; filternr: number; }): Promise<ApiResponse<PiezometerData>> {
        const response = await super.fetch<PiezometerData>(config.grondwater.url, config.grondwater.actions.factsheet, [params.siteid, params.boringnr, params.filternr]);
        return response;
    }

    static async exportPiezometerData(params: { siteid: number; boringnrs: number[]; title?: string; filename: string; }): Promise<ApiResponse<string>> {
        const filename = `${params.filename}.pdf`;
        const response = await super.fetchFile(config.grondwater.url, `${config.grondwater.actions.factsheetExport}/${params.siteid}?${params.boringnrs.map(v => `boringnr=${v}`).join('&')}${params.title ? `&title=${params.title}` : ''}`);
        return response.success ? super.pushFile(response, filename) : response;
    }

    static async hasFullAccess(): Promise<ApiResponse<boolean>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.assessmentFullAccess) as ApiResponse<boolean>;
        return response;
    }

    static async createAssessment(params: { projectnr: number, boringnr: number, filternr: number, assessment: Assessment }): Promise<ApiResponse<Assessment[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.assessmentCreate, [params.projectnr, params.boringnr, params.filternr], params.assessment) as ApiResponse<Assessment[]>;
        return response;
    }

    static async updateAssessment(params: { assessment: Assessment; }): Promise<ApiResponse<Assessment[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.assessmentUpdate, [params.assessment.guid], params.assessment) as ApiResponse<Assessment[]>;
        return response;
    }

    static async deleteAssessment(params: { assessment: Assessment; }): Promise<ApiResponse<Assessment[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.assessmentDelete, [params.assessment.guid], params.assessment) as ApiResponse<Assessment[]>;
        return response;
    }

    static async createGraphAnnotation(params: { projectnr: number, boringnr: number, filternr: number, graphAnnotation: GraphAnnotation }): Promise<ApiResponse<GraphAnnotation[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.graphAnnotationCreate, [params.projectnr, params.boringnr, params.filternr], params.graphAnnotation) as ApiResponse<GraphAnnotation[]>;
        return response;
    }

    static async updateGraphAnnotation(params: { graphAnnotation: GraphAnnotation; }): Promise<ApiResponse<GraphAnnotation[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.graphAnnotationUpdate, [params.graphAnnotation.id], params.graphAnnotation) as ApiResponse<GraphAnnotation[]>;
        return response;
    }

    static async deleteGraphAnnotation(params: { graphAnnotation: GraphAnnotation; }): Promise<ApiResponse<GraphAnnotation[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.graphAnnotationDelete, [params.graphAnnotation.id], params.graphAnnotation) as ApiResponse<GraphAnnotation[]>;
        return response;
    }

    static async getValidationInfo(params: { project: number; boring: number; filter: number; timestamps: number[]; }): Promise<ApiResponse<gwm_api.ValidationCollection>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.validationInfo, [params.project, params.boring, params.filter], params.timestamps, 'blob') as ApiResponse<Blob>;
        const samplesResponse: ApiResponse<gwm_api.ValidationCollection> = {
            success: false,
            error: response.error
        };
        if (response.data) {
            const blob = response.data;
            const bytes = new Uint8Array(await blob.arrayBuffer());
            const samples = gwm_api.ValidationCollection.decode(bytes);
            samplesResponse.data = samples;
            samplesResponse.success = true;
        }
        return samplesResponse;
    }

    static async updateValidationInfo(params: { project: number; boring: number; filter: number; collection: gwm_api.ValidationCollection; }): Promise<ApiResponse<boolean>> {
        const sample = params.collection.values[0];
        if (!sample) return { success: false };

        return await super.put(config.grondwater.url, config.grondwater.actions.validationUpdate, [params.project, params.boring, params.filter], {
            score: sample.value,
            state: sample.state,
            reason: sample.reason,
            interpolation: sample.interpolation,
            limit: sample.limit,
            timestamps: params.collection.values.map(v => v.timestamp)
        }) as ApiResponse<boolean>;
    }

    static async updateAutoControl(params: { project: number; boring: number; filter: number; }): Promise<ApiResponse<boolean>> {
        return await super.get(config.grondwater.url, config.grondwater.actions.validationAutoControl, [params.project, params.boring, params.filter]) as ApiResponse<boolean>;
    }
}

export class GwmPublicApi extends ApiBase {
    // private static adapter?: Remote<ApiWorker>;

    static configure(basePath: string, accessToken?: string): void {
        super.configure(basePath, accessToken);
    }

    static async getSettings(params: { siteid: number; }): Promise<ApiResponse<PublicSettings | null>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.publicSettings, [params.siteid]) as ApiResponse<PublicSettings | null>;
        return response;
    }

    static async getInfo(params: { siteid: number; boringnrs: string[]; }): Promise<ApiResponse<Meetpunt[]>> {
        const response = await super.fetch(config.grondwater.url, `${config.grondwater.actions.info}/${params.siteid}?${params.boringnrs.map(v => `boringnr=${v}`).join('&')}`) as ApiResponse<Meetpunt[]>;
        return response;
    }

    static async getData(params: { siteid: number; piezometer: string; }): Promise<ApiResponse<gwm_api.SampleCollection>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.data, [params.siteid, params.piezometer], undefined, 'blob') as ApiResponse<Blob>;
        const samplesResponse: ApiResponse<gwm_api.SampleCollection> = {
            success: false,
            error: response.error
        };
        if (response.data) {
            const blob = response.data;
            const bytes = new Uint8Array(await blob.arrayBuffer());
            const samples = gwm_api.SampleCollection.decode(bytes);
            samplesResponse.data = samples;
            samplesResponse.success = true;
        }
        return samplesResponse;
    }

    static async getWeatherStations(params: { start: Moment; end: Moment; site?: number }): Promise<ApiResponse<WeatherStation[]>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.weatherStations, [params.start.toISOString(), params.end.toISOString(), params.site]) as ApiResponse<WeatherStation[]>;
        return response;
    }

    static async getWeatherData(params: { stationOrSite: number; piezometer?: string; start: Moment; end: Moment; }): Promise<ApiResponse<gwm_api.WeatherCollection>> {
        const response = await super.fetch(config.grondwater.url, config.grondwater.actions.weatherData, [params.start.toISOString(), params.end.toISOString(), params.stationOrSite, params.piezometer], undefined, 'blob') as ApiResponse<Blob>;
        const samplesResponse: ApiResponse<gwm_api.WeatherCollection> = {
            success: false,
            error: response.error
        };
        if (response.data) {
            const blob = response.data;
            const bytes = new Uint8Array(await blob.arrayBuffer());
            const samples = gwm_api.WeatherCollection.decode(bytes);
            samplesResponse.data = samples;
            samplesResponse.success = true;
        }
        return samplesResponse;
    }

    static async exportData(params: { siteid: number; piezometers: string[]; filename: string; format: 'xlsx' | 'csv'; }): Promise<ApiResponse<[number, number][][][]>> {
        const filename = `${params.filename}.${params.format}`;
        const response = await super.fetchFile(config.grondwater.url, `${config.grondwater.actions.export}/${params.siteid}?${params.piezometers.map(v => `piezometer=${v}`).join('&')}&format=${params.format}`);
        return response.success ? super.pushFile(response, filename) : response;
    }

    static async getPiezometerData(params: { siteid: number; piezometer: string; }): Promise<ApiResponse<PiezometerData>> {
        const response = await super.fetch<PiezometerData>(config.grondwater.url, config.grondwater.actions.factsheet, [params.siteid, params.piezometer]);
        return response;
    }

    static async exportPiezometerData(params: { siteid: number; piezometer: string[]; title?: string; filename: string; }): Promise<ApiResponse<string>> {
        const filename = `${params.filename}.pdf`;
        const response = await super.fetchFile(config.grondwater.url, `${config.grondwater.actions.factsheetExport}/${params.siteid}?${params.piezometer.map(v => `piezometer=${v}`).join('&')}${params.title ? `&title=${params.title}` : ''}`);
        return response.success ? super.pushFile(response, filename) : response;
    }
}