import { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { ChartItem } from '../models/ChartValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { sortBy } from 'lodash';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { TooltipProps } from 'recharts';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

interface TooltipWrapperProps extends TooltipProps<number, string> {
    labels: Record<string, string>[];
    container?: Element;

    dataMin?: number;
    dataMax?: number;
    ghg?: number;
    glg?: number;
    rhg?: number;
    rlg?: number;
    nap?: number;

    onDataMinToggled?: (state: boolean) => void;
    onDataMaxToggled?: (state: boolean) => void;
    onGhgToggled?: (state: boolean) => void;
    onGlgToggled?: (state: boolean) => void;
    onRhgToggled?: (state: boolean) => void;
    onRlgToggled?: (state: boolean) => void;
    onNapToggled?: (state: boolean) => void;
}

interface TooltipWrapperState {
    dataMin: boolean;
    dataMax: boolean;
    ghg: boolean;
    glg: boolean;
    rhg: boolean;
    rlg: boolean;
    nap: boolean;
}

export class TooltipWrapper extends Component<TooltipWrapperProps, TooltipWrapperState> {
    static defaultProps = {
        payload: []
    };

    constructor(props: Readonly<TooltipWrapperProps>) {
        super(props);

        this.state = {
            dataMax: false,
            dataMin: false,
            ghg: false,
            glg: false,
            rhg: false,
            rlg: false,
            nap: false
        };
    }

    componentDidMount(): void {
        if (this.props.container) {
            const tooltip = this.renderTooltip();
            ReactDOM.render(tooltip, this.props.container);
        }
    }

    componentDidUpdate(prevProps: Readonly<TooltipWrapperProps>, prevState: Readonly<TooltipWrapperState>): void {
        if (this.props.container) {
            const tooltip = this.renderTooltip();
            ReactDOM.render(tooltip, this.props.container);
        }

        if (this.state.dataMax != prevState.dataMax) {
            this.props.onDataMaxToggled?.(this.state.dataMax);
        }
        if (this.state.dataMin != prevState.dataMin) {
            this.props.onDataMinToggled?.(this.state.dataMin);
        }
        if (this.state.ghg != prevState.ghg) {
            this.props.onGhgToggled?.(this.state.ghg);
        }
        if (this.state.glg != prevState.glg) {
            this.props.onGlgToggled?.(this.state.glg);
        }
        if (this.state.rhg != prevState.rhg) {
            this.props.onRhgToggled?.(this.state.rhg);
        }
        if (this.state.rlg != prevState.rlg) {
            this.props.onRlgToggled?.(this.state.rlg);
        }
        if (this.state.nap != prevState.nap) {
            this.props.onNapToggled?.(this.state.nap);
        }
    }

    componentWillUnmount(): void {
        if (this.props.container) {
            ReactDOM.unmountComponentAtNode(this.props.container);
        }
    }

    renderTooltip(): JSX.Element {
        const { payload } = this.props;
        const info = payload?.[0]?.payload as ChartItem | undefined;

        return (
            <>
                <div className="center-block info-placeholder">
                    <div className="d-flex flex-row justify-content-between">
                        <h3>Samenvatting</h3>

                        <OverlayTrigger
                            trigger={['focus', 'hover']}
                            placement="left"
                            overlay={(
                                <Popover id="popoverGraphInfo">
                                    <Popover.Title as="h3">Informatie</Popover.Title>
                                    <Popover.Content>
                                        <ul>
                                            <li>
                                                De GxG waarden zijn op klassieke manier bepaald. Dit is gedaan door de 3 hoogste/laagste metingen in een jaar op de 14e en 28e van elke maand te nemen en daarvan het gemiddelde te berekenen. Voor elk jaar is deze waarde bepaald en daarvan het gemiddelde. Voor een betrouwbare GxG is minimaal een meetreeks van 8 jaar nodig.
                                            </li>
                                            <li>
                                                In de grafiek zijn de reeksen aan en uit te zetten door op de reeksnaam te klikken.
                                            </li>
                                            <li>
                                            De RxG waarden zijn een alternatief voor de GxG waarden aangezien niet altijd een reeks van 8 jaar beschikbaar is. De RxG waarden betreffen de 8 en 92 percentiel waarde van de gehele reeks. Dit komt overeen met een grondwaterstand die gedurende 30 dagen per jaar voor kan komen en is tevens de HG2 en/of LG2 waarde over 24 metingen. In de praktijk blijkt dit een zeer goede benadering van de GxG waarden te zijn.
                                            </li>
                                        </ul>
                                    </Popover.Content>
                                </Popover>
                            )}>
                            <button className="btn btn-light text-primary">
                                <FontAwesomeIcon icon={faCircleInfo} size={'lg'} />
                            </button>
                        </OverlayTrigger>
                    </div>

                    <table className="table table-condensed table-hover">
                        <tbody>
                            {this.props.dataMax !== undefined && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ dataMax: !this.state.dataMax })}>
                                        <FontAwesomeIcon icon={this.state.dataMax ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">Max</th>
                                <td className="max-gw">{this.formatValue(this.props.dataMax)}</td>
                            </tr>}
                            {this.props.dataMin !== undefined && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ dataMin: !this.state.dataMin })}>
                                        <FontAwesomeIcon icon={this.state.dataMin ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">Min</th>
                                <td className="min-gw">{this.formatValue(this.props.dataMin)}</td>
                            </tr>}
                            {this.props.ghg !== undefined  && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ ghg: !this.state.ghg })}>
                                        <FontAwesomeIcon icon={this.state.ghg ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">GHG</th>
                                <td className="ghg-gw">{this.formatValue(this.props.ghg)}</td>
                            </tr>}
                            {this.props.glg !== undefined && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ glg: !this.state.glg })}>
                                        <FontAwesomeIcon icon={this.state.glg ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">GLG</th>
                                <td className="glg-gw">{this.formatValue(this.props.glg)}</td>
                            </tr>}
                            {this.props.rhg !== undefined  && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ rhg: !this.state.rhg })}>
                                        <FontAwesomeIcon icon={this.state.rhg ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">RHG</th>
                                <td className="rhg-gw">{this.formatValue(this.props.rhg)}</td>
                            </tr>}
                            {this.props.rlg !== undefined && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ rlg: !this.state.rlg })}>
                                        <FontAwesomeIcon icon={this.state.rlg ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">RLG</th>
                                <td className="rlg-gw">{this.formatValue(this.props.rlg)}</td>
                            </tr>}
                            {this.props.nap !== undefined  && <tr>
                                <td className="plot-toggle">
                                    <a href="#" onClick={() => this.setState({ nap: !this.state.nap })}>
                                        <FontAwesomeIcon icon={this.state.nap ? faSquareCheck : faSquare} />
                                    </a>
                                </td>
                                <th className="item-header">MV</th>
                                <td className="nap-gw">{this.formatValue(this.props.nap)}</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>

                <div>
                    {info && payload ? (
                        <table className="table table-condensed table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan={2}>
                                        <strong>{moment(info.time).format('DD MMM YYYY HH:mm')}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortBy(payload.filter(v => v.name && !['max', 'min', 'ghg', 'glg', 'rhg', 'rlg', 'nap'].includes(v.name)), v => v.dataKey)
                                    .map(v => {
                                        const point = v.payload as ChartItem | undefined;
                                        return point ? (
                                            <tr key={`tooltip_${v.name}`}>
                                                <th scope="row">
                                                    {v.name ? this.props.labels[v.name] ?? v.name : '-'}
                                                </th>
                                                <td>
                                                    {v.value ? this.formatValue(v.value) : '-'}
                                                </td>
                                            </tr>
                                        ) : undefined;
                                    })}
                            </tbody>
                        </table>
                    ) : undefined}
                </div>
            </>
        );
    }

    formatValue(value: number): string {
        return `${Math.round(value * 1000) / 1000}`;
    }

    render(): JSX.Element | null {
        return null;
    }
}

export default TooltipWrapper;