import { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import moment from 'moment';
import { isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarRange, faEraser } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface DateFilterProps {
    dateRangeMin: number;
    dateRangeMax: number;
    current: [number, number];
    onDateRangeChanged?: (min: number, max: number) => void;
    onDateRangeReset?: () => void;
    onClear?: () => void;
}
interface DateFilterState {
    min: number;
    max: number;
    days: number;
    currentValue: number[];
    revision: number;
}

const DAY_IN_MILLISECONDS = 86400000;

export class DateFilter extends Component<DateFilterProps, DateFilterState> {
    constructor(props: Readonly<DateFilterProps>) {
        super(props);

        const days = Math.round((props.dateRangeMax - props.dateRangeMin) / DAY_IN_MILLISECONDS);

        this.state = {
            min: props.dateRangeMin,
            max: props.dateRangeMax,
            days: days,
            currentValue: [0, days],
            revision: 0
        };
    }

    componentDidUpdate(prevProps: Readonly<DateFilterProps>, prevState: Readonly<DateFilterState>) {
        if (this.props.current[0] != prevProps.current[0] || this.props.current[1] != prevProps.current[1]) {
            const min = Math.round((this.props.current[0] - this.props.dateRangeMin) / DAY_IN_MILLISECONDS);
            const max = Math.round((this.props.current[1] - this.props.dateRangeMin) / DAY_IN_MILLISECONDS);

            if (this.state.currentValue[0] != min || this.state.currentValue[1] != max) {
                this.setState(() => ({
                    revision: this.state.revision + 1,
                    currentValue: [min, max]
                }));
            }
        }

        if (!isEqual(this.state.currentValue, prevState.currentValue)) {
            const values = this.state.currentValue;

            if (values[0] != 0 || values[1] != this.state.days) {
                const min = this.props.dateRangeMin + (values[0] * DAY_IN_MILLISECONDS);
                const max = this.props.dateRangeMin + (values[1] * DAY_IN_MILLISECONDS);
                
                this.props.onDateRangeChanged?.(min, max);
            } else {
                this.props.onDateRangeReset?.();
            }
        }
    }

    render(): JSX.Element {

        const Range = Slider.createSliderWithTooltip(Slider.Range);
        const rangeNode = (
            <Range key={this.state.revision} defaultValue={this.state.currentValue} pushable 
                min={0} max={this.state.days} 
                onAfterChange={(value) => this.setState({ currentValue: value })} 
                tipFormatter={(value: number) => moment(this.state.min).add(value, 'days').format('DD MMM YYYY')} />
        );

        return (
            <div className="d-flex flex-row w-100 align-items-center mt-1 pl-4 pr-2">
                <div className="mr-3 text-light-cyan">
                    <FontAwesomeIcon icon={faCalendarRange} size="lg" />
                </div>

                <div className="date-filter flex-fill">
                    {rangeNode}
                </div>

                <OverlayTrigger trigger={['focus', 'hover']}
                    placement="bottom"
                    overlay={(
                        <Tooltip id="tooltip-eraser" >
                            {app.translator.translate('Reset Zoom')}
                        </Tooltip>
                    )}
                >       
                    <a className="text-danger text-decoration-none ml-3" href="#"
                        onClick={() => this.props.onClear?.()}>

                        <FontAwesomeIcon icon={faEraser} />
                    </a>
                </OverlayTrigger>
                     
            </div>
        );
    }
}