/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.gwm_api = (function() {

    /**
     * Namespace gwm_api.
     * @exports gwm_api
     * @namespace
     */
    var gwm_api = {};

    gwm_api.SampleCollection = (function() {

        /**
         * Properties of a SampleCollection.
         * @memberof gwm_api
         * @interface ISampleCollection
         * @property {Array.<gwm_api.ISample>|null} [values] SampleCollection values
         */

        /**
         * Constructs a new SampleCollection.
         * @memberof gwm_api
         * @classdesc Represents a SampleCollection.
         * @implements ISampleCollection
         * @constructor
         * @param {gwm_api.ISampleCollection=} [properties] Properties to set
         */
        function SampleCollection(properties) {
            this.values = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SampleCollection values.
         * @member {Array.<gwm_api.ISample>} values
         * @memberof gwm_api.SampleCollection
         * @instance
         */
        SampleCollection.prototype.values = $util.emptyArray;

        /**
         * Creates a new SampleCollection instance using the specified properties.
         * @function create
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {gwm_api.ISampleCollection=} [properties] Properties to set
         * @returns {gwm_api.SampleCollection} SampleCollection instance
         */
        SampleCollection.create = function create(properties) {
            return new SampleCollection(properties);
        };

        /**
         * Encodes the specified SampleCollection message. Does not implicitly {@link gwm_api.SampleCollection.verify|verify} messages.
         * @function encode
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {gwm_api.ISampleCollection} message SampleCollection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SampleCollection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.values != null && message.values.length)
                for (var i = 0; i < message.values.length; ++i)
                    $root.gwm_api.Sample.encode(message.values[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SampleCollection message, length delimited. Does not implicitly {@link gwm_api.SampleCollection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {gwm_api.ISampleCollection} message SampleCollection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SampleCollection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SampleCollection message from the specified reader or buffer.
         * @function decode
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gwm_api.SampleCollection} SampleCollection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SampleCollection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.gwm_api.SampleCollection();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.gwm_api.Sample.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SampleCollection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gwm_api.SampleCollection} SampleCollection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SampleCollection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SampleCollection message.
         * @function verify
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SampleCollection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (var i = 0; i < message.values.length; ++i) {
                    var error = $root.gwm_api.Sample.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SampleCollection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gwm_api.SampleCollection} SampleCollection
         */
        SampleCollection.fromObject = function fromObject(object) {
            if (object instanceof $root.gwm_api.SampleCollection)
                return object;
            var message = new $root.gwm_api.SampleCollection();
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".gwm_api.SampleCollection.values: array expected");
                message.values = [];
                for (var i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".gwm_api.SampleCollection.values: object expected");
                    message.values[i] = $root.gwm_api.Sample.fromObject(object.values[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SampleCollection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gwm_api.SampleCollection
         * @static
         * @param {gwm_api.SampleCollection} message SampleCollection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SampleCollection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (message.values && message.values.length) {
                object.values = [];
                for (var j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.gwm_api.Sample.toObject(message.values[j], options);
            }
            return object;
        };

        /**
         * Converts this SampleCollection to JSON.
         * @function toJSON
         * @memberof gwm_api.SampleCollection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SampleCollection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SampleCollection;
    })();

    gwm_api.Sample = (function() {

        /**
         * Properties of a Sample.
         * @memberof gwm_api
         * @interface ISample
         * @property {number|null} [value] Sample value
         * @property {number|Long|null} [timestamp] Sample timestamp
         * @property {boolean|null} [valid] Sample valid
         * @property {boolean|null} [manual] Sample manual
         * @property {boolean|null} [released] Sample released
         */

        /**
         * Constructs a new Sample.
         * @memberof gwm_api
         * @classdesc Represents a Sample.
         * @implements ISample
         * @constructor
         * @param {gwm_api.ISample=} [properties] Properties to set
         */
        function Sample(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Sample value.
         * @member {number} value
         * @memberof gwm_api.Sample
         * @instance
         */
        Sample.prototype.value = 0;

        /**
         * Sample timestamp.
         * @member {number|Long} timestamp
         * @memberof gwm_api.Sample
         * @instance
         */
        Sample.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Sample valid.
         * @member {boolean} valid
         * @memberof gwm_api.Sample
         * @instance
         */
        Sample.prototype.valid = false;

        /**
         * Sample manual.
         * @member {boolean} manual
         * @memberof gwm_api.Sample
         * @instance
         */
        Sample.prototype.manual = false;

        /**
         * Sample released.
         * @member {boolean} released
         * @memberof gwm_api.Sample
         * @instance
         */
        Sample.prototype.released = false;

        /**
         * Creates a new Sample instance using the specified properties.
         * @function create
         * @memberof gwm_api.Sample
         * @static
         * @param {gwm_api.ISample=} [properties] Properties to set
         * @returns {gwm_api.Sample} Sample instance
         */
        Sample.create = function create(properties) {
            return new Sample(properties);
        };

        /**
         * Encodes the specified Sample message. Does not implicitly {@link gwm_api.Sample.verify|verify} messages.
         * @function encode
         * @memberof gwm_api.Sample
         * @static
         * @param {gwm_api.ISample} message Sample message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sample.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestamp);
            if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.valid);
            if (message.manual != null && Object.hasOwnProperty.call(message, "manual"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.manual);
            if (message.released != null && Object.hasOwnProperty.call(message, "released"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.released);
            return writer;
        };

        /**
         * Encodes the specified Sample message, length delimited. Does not implicitly {@link gwm_api.Sample.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gwm_api.Sample
         * @static
         * @param {gwm_api.ISample} message Sample message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sample.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Sample message from the specified reader or buffer.
         * @function decode
         * @memberof gwm_api.Sample
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gwm_api.Sample} Sample
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sample.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.gwm_api.Sample();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.double();
                    break;
                case 2:
                    message.timestamp = reader.int64();
                    break;
                case 3:
                    message.valid = reader.bool();
                    break;
                case 4:
                    message.manual = reader.bool();
                    break;
                case 5:
                    message.released = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Sample message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gwm_api.Sample
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gwm_api.Sample} Sample
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sample.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Sample message.
         * @function verify
         * @memberof gwm_api.Sample
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Sample.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "number")
                    return "value: number expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.valid != null && message.hasOwnProperty("valid"))
                if (typeof message.valid !== "boolean")
                    return "valid: boolean expected";
            if (message.manual != null && message.hasOwnProperty("manual"))
                if (typeof message.manual !== "boolean")
                    return "manual: boolean expected";
            if (message.released != null && message.hasOwnProperty("released"))
                if (typeof message.released !== "boolean")
                    return "released: boolean expected";
            return null;
        };

        /**
         * Creates a Sample message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gwm_api.Sample
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gwm_api.Sample} Sample
         */
        Sample.fromObject = function fromObject(object) {
            if (object instanceof $root.gwm_api.Sample)
                return object;
            var message = new $root.gwm_api.Sample();
            if (object.value != null)
                message.value = Number(object.value);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            if (object.valid != null)
                message.valid = Boolean(object.valid);
            if (object.manual != null)
                message.manual = Boolean(object.manual);
            if (object.released != null)
                message.released = Boolean(object.released);
            return message;
        };

        /**
         * Creates a plain object from a Sample message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gwm_api.Sample
         * @static
         * @param {gwm_api.Sample} message Sample
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Sample.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.value = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.valid = false;
                object.manual = false;
                object.released = false;
            }
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            if (message.valid != null && message.hasOwnProperty("valid"))
                object.valid = message.valid;
            if (message.manual != null && message.hasOwnProperty("manual"))
                object.manual = message.manual;
            if (message.released != null && message.hasOwnProperty("released"))
                object.released = message.released;
            return object;
        };

        /**
         * Converts this Sample to JSON.
         * @function toJSON
         * @memberof gwm_api.Sample
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Sample.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Sample;
    })();

    gwm_api.ValidationCollection = (function() {

        /**
         * Properties of a ValidationCollection.
         * @memberof gwm_api
         * @interface IValidationCollection
         * @property {Array.<gwm_api.IValidation>|null} [values] ValidationCollection values
         */

        /**
         * Constructs a new ValidationCollection.
         * @memberof gwm_api
         * @classdesc Represents a ValidationCollection.
         * @implements IValidationCollection
         * @constructor
         * @param {gwm_api.IValidationCollection=} [properties] Properties to set
         */
        function ValidationCollection(properties) {
            this.values = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidationCollection values.
         * @member {Array.<gwm_api.IValidation>} values
         * @memberof gwm_api.ValidationCollection
         * @instance
         */
        ValidationCollection.prototype.values = $util.emptyArray;

        /**
         * Creates a new ValidationCollection instance using the specified properties.
         * @function create
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {gwm_api.IValidationCollection=} [properties] Properties to set
         * @returns {gwm_api.ValidationCollection} ValidationCollection instance
         */
        ValidationCollection.create = function create(properties) {
            return new ValidationCollection(properties);
        };

        /**
         * Encodes the specified ValidationCollection message. Does not implicitly {@link gwm_api.ValidationCollection.verify|verify} messages.
         * @function encode
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {gwm_api.IValidationCollection} message ValidationCollection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidationCollection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.values != null && message.values.length)
                for (var i = 0; i < message.values.length; ++i)
                    $root.gwm_api.Validation.encode(message.values[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ValidationCollection message, length delimited. Does not implicitly {@link gwm_api.ValidationCollection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {gwm_api.IValidationCollection} message ValidationCollection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidationCollection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidationCollection message from the specified reader or buffer.
         * @function decode
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gwm_api.ValidationCollection} ValidationCollection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidationCollection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.gwm_api.ValidationCollection();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.gwm_api.Validation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidationCollection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gwm_api.ValidationCollection} ValidationCollection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidationCollection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidationCollection message.
         * @function verify
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidationCollection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (var i = 0; i < message.values.length; ++i) {
                    var error = $root.gwm_api.Validation.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ValidationCollection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gwm_api.ValidationCollection} ValidationCollection
         */
        ValidationCollection.fromObject = function fromObject(object) {
            if (object instanceof $root.gwm_api.ValidationCollection)
                return object;
            var message = new $root.gwm_api.ValidationCollection();
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".gwm_api.ValidationCollection.values: array expected");
                message.values = [];
                for (var i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".gwm_api.ValidationCollection.values: object expected");
                    message.values[i] = $root.gwm_api.Validation.fromObject(object.values[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ValidationCollection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gwm_api.ValidationCollection
         * @static
         * @param {gwm_api.ValidationCollection} message ValidationCollection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidationCollection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (message.values && message.values.length) {
                object.values = [];
                for (var j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.gwm_api.Validation.toObject(message.values[j], options);
            }
            return object;
        };

        /**
         * Converts this ValidationCollection to JSON.
         * @function toJSON
         * @memberof gwm_api.ValidationCollection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidationCollection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ValidationCollection;
    })();

    gwm_api.Validation = (function() {

        /**
         * Properties of a Validation.
         * @memberof gwm_api
         * @interface IValidation
         * @property {number|Long|null} [timestamp] Validation timestamp
         * @property {gwm_api.Validation.Score|null} [value] Validation value
         * @property {gwm_api.Validation.Interpolation|null} [interpolation] Validation interpolation
         * @property {gwm_api.Validation.Reason|null} [reason] Validation reason
         * @property {gwm_api.Validation.State|null} [state] Validation state
         * @property {number|null} [limit] Validation limit
         */

        /**
         * Constructs a new Validation.
         * @memberof gwm_api
         * @classdesc Represents a Validation.
         * @implements IValidation
         * @constructor
         * @param {gwm_api.IValidation=} [properties] Properties to set
         */
        function Validation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Validation timestamp.
         * @member {number|Long} timestamp
         * @memberof gwm_api.Validation
         * @instance
         */
        Validation.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Validation value.
         * @member {gwm_api.Validation.Score} value
         * @memberof gwm_api.Validation
         * @instance
         */
        Validation.prototype.value = 0;

        /**
         * Validation interpolation.
         * @member {gwm_api.Validation.Interpolation} interpolation
         * @memberof gwm_api.Validation
         * @instance
         */
        Validation.prototype.interpolation = 0;

        /**
         * Validation reason.
         * @member {gwm_api.Validation.Reason} reason
         * @memberof gwm_api.Validation
         * @instance
         */
        Validation.prototype.reason = 0;

        /**
         * Validation state.
         * @member {gwm_api.Validation.State} state
         * @memberof gwm_api.Validation
         * @instance
         */
        Validation.prototype.state = 0;

        /**
         * Validation limit.
         * @member {number} limit
         * @memberof gwm_api.Validation
         * @instance
         */
        Validation.prototype.limit = 0;

        /**
         * Creates a new Validation instance using the specified properties.
         * @function create
         * @memberof gwm_api.Validation
         * @static
         * @param {gwm_api.IValidation=} [properties] Properties to set
         * @returns {gwm_api.Validation} Validation instance
         */
        Validation.create = function create(properties) {
            return new Validation(properties);
        };

        /**
         * Encodes the specified Validation message. Does not implicitly {@link gwm_api.Validation.verify|verify} messages.
         * @function encode
         * @memberof gwm_api.Validation
         * @static
         * @param {gwm_api.IValidation} message Validation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Validation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestamp);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.interpolation != null && Object.hasOwnProperty.call(message, "interpolation"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.interpolation);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.reason);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.state);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.limit);
            return writer;
        };

        /**
         * Encodes the specified Validation message, length delimited. Does not implicitly {@link gwm_api.Validation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gwm_api.Validation
         * @static
         * @param {gwm_api.IValidation} message Validation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Validation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Validation message from the specified reader or buffer.
         * @function decode
         * @memberof gwm_api.Validation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gwm_api.Validation} Validation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Validation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.gwm_api.Validation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timestamp = reader.int64();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.interpolation = reader.int32();
                    break;
                case 4:
                    message.reason = reader.int32();
                    break;
                case 5:
                    message.state = reader.int32();
                    break;
                case 6:
                    message.limit = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Validation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gwm_api.Validation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gwm_api.Validation} Validation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Validation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Validation message.
         * @function verify
         * @memberof gwm_api.Validation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Validation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.value != null && message.hasOwnProperty("value"))
                switch (message.value) {
                default:
                    return "value: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.interpolation != null && message.hasOwnProperty("interpolation"))
                switch (message.interpolation) {
                default:
                    return "interpolation: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (typeof message.limit !== "number")
                    return "limit: number expected";
            return null;
        };

        /**
         * Creates a Validation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gwm_api.Validation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gwm_api.Validation} Validation
         */
        Validation.fromObject = function fromObject(object) {
            if (object instanceof $root.gwm_api.Validation)
                return object;
            var message = new $root.gwm_api.Validation();
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            switch (object.value) {
            case "SCORE_NOT_SPECIFIED":
            case 0:
                message.value = 0;
                break;
            case "SCORE_VALID":
            case 1:
                message.value = 1;
                break;
            case "SCORE_INVALID":
            case 2:
                message.value = 2;
                break;
            case "SCORE_REMOVED":
            case 3:
                message.value = 3;
                break;
            }
            switch (object.interpolation) {
            case "INTERPOLATION_NOT_SPECIFIED":
            case 0:
                message.interpolation = 0;
                break;
            case "INTERPOLATION_DISCONTINUOUS":
            case 1:
                message.interpolation = 1;
                break;
            }
            switch (object.reason) {
            case "REASON_NOT_SPECIFIED":
            case 0:
                message.reason = 0;
                break;
            case "REASON_GREATER_THAN_LIMIT":
            case 1:
                message.reason = 1;
                break;
            case "REASON_LESS_THEN_LIMIT":
            case 2:
                message.reason = 2;
                break;
            case "REASON_UNKNOWN":
            case 3:
                message.reason = 3;
                break;
            }
            switch (object.state) {
            case "STATE_NOT_SPECIFIED":
            case 0:
                message.state = 0;
                break;
            case "STATE_REJECTED":
            case 1:
                message.state = 1;
                break;
            case "STATE_APPROVED":
            case 2:
                message.state = 2;
                break;
            case "STATE_NOT_RATED":
            case 3:
                message.state = 3;
                break;
            case "STATE_UNDECIDED":
            case 4:
                message.state = 4;
                break;
            case "STATE_UNKNOWN":
            case 5:
                message.state = 5;
                break;
            }
            if (object.limit != null)
                message.limit = Number(object.limit);
            return message;
        };

        /**
         * Creates a plain object from a Validation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gwm_api.Validation
         * @static
         * @param {gwm_api.Validation} message Validation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Validation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.value = options.enums === String ? "SCORE_NOT_SPECIFIED" : 0;
                object.interpolation = options.enums === String ? "INTERPOLATION_NOT_SPECIFIED" : 0;
                object.reason = options.enums === String ? "REASON_NOT_SPECIFIED" : 0;
                object.state = options.enums === String ? "STATE_NOT_SPECIFIED" : 0;
                object.limit = 0;
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.enums === String ? $root.gwm_api.Validation.Score[message.value] : message.value;
            if (message.interpolation != null && message.hasOwnProperty("interpolation"))
                object.interpolation = options.enums === String ? $root.gwm_api.Validation.Interpolation[message.interpolation] : message.interpolation;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.gwm_api.Validation.Reason[message.reason] : message.reason;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.gwm_api.Validation.State[message.state] : message.state;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = options.json && !isFinite(message.limit) ? String(message.limit) : message.limit;
            return object;
        };

        /**
         * Converts this Validation to JSON.
         * @function toJSON
         * @memberof gwm_api.Validation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Validation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Score enum.
         * @name gwm_api.Validation.Score
         * @enum {number}
         * @property {number} SCORE_NOT_SPECIFIED=0 SCORE_NOT_SPECIFIED value
         * @property {number} SCORE_VALID=1 SCORE_VALID value
         * @property {number} SCORE_INVALID=2 SCORE_INVALID value
         * @property {number} SCORE_REMOVED=3 SCORE_REMOVED value
         */
        Validation.Score = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SCORE_NOT_SPECIFIED"] = 0;
            values[valuesById[1] = "SCORE_VALID"] = 1;
            values[valuesById[2] = "SCORE_INVALID"] = 2;
            values[valuesById[3] = "SCORE_REMOVED"] = 3;
            return values;
        })();

        /**
         * State enum.
         * @name gwm_api.Validation.State
         * @enum {number}
         * @property {number} STATE_NOT_SPECIFIED=0 STATE_NOT_SPECIFIED value
         * @property {number} STATE_REJECTED=1 STATE_REJECTED value
         * @property {number} STATE_APPROVED=2 STATE_APPROVED value
         * @property {number} STATE_NOT_RATED=3 STATE_NOT_RATED value
         * @property {number} STATE_UNDECIDED=4 STATE_UNDECIDED value
         * @property {number} STATE_UNKNOWN=5 STATE_UNKNOWN value
         */
        Validation.State = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "STATE_NOT_SPECIFIED"] = 0;
            values[valuesById[1] = "STATE_REJECTED"] = 1;
            values[valuesById[2] = "STATE_APPROVED"] = 2;
            values[valuesById[3] = "STATE_NOT_RATED"] = 3;
            values[valuesById[4] = "STATE_UNDECIDED"] = 4;
            values[valuesById[5] = "STATE_UNKNOWN"] = 5;
            return values;
        })();

        /**
         * Interpolation enum.
         * @name gwm_api.Validation.Interpolation
         * @enum {number}
         * @property {number} INTERPOLATION_NOT_SPECIFIED=0 INTERPOLATION_NOT_SPECIFIED value
         * @property {number} INTERPOLATION_DISCONTINUOUS=1 INTERPOLATION_DISCONTINUOUS value
         */
        Validation.Interpolation = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INTERPOLATION_NOT_SPECIFIED"] = 0;
            values[valuesById[1] = "INTERPOLATION_DISCONTINUOUS"] = 1;
            return values;
        })();

        /**
         * Reason enum.
         * @name gwm_api.Validation.Reason
         * @enum {number}
         * @property {number} REASON_NOT_SPECIFIED=0 REASON_NOT_SPECIFIED value
         * @property {number} REASON_GREATER_THAN_LIMIT=1 REASON_GREATER_THAN_LIMIT value
         * @property {number} REASON_LESS_THEN_LIMIT=2 REASON_LESS_THEN_LIMIT value
         * @property {number} REASON_UNKNOWN=3 REASON_UNKNOWN value
         */
        Validation.Reason = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "REASON_NOT_SPECIFIED"] = 0;
            values[valuesById[1] = "REASON_GREATER_THAN_LIMIT"] = 1;
            values[valuesById[2] = "REASON_LESS_THEN_LIMIT"] = 2;
            values[valuesById[3] = "REASON_UNKNOWN"] = 3;
            return values;
        })();

        return Validation;
    })();

    gwm_api.WeatherCollection = (function() {

        /**
         * Properties of a WeatherCollection.
         * @memberof gwm_api
         * @interface IWeatherCollection
         * @property {Array.<gwm_api.IWeather>|null} [values] WeatherCollection values
         */

        /**
         * Constructs a new WeatherCollection.
         * @memberof gwm_api
         * @classdesc Represents a WeatherCollection.
         * @implements IWeatherCollection
         * @constructor
         * @param {gwm_api.IWeatherCollection=} [properties] Properties to set
         */
        function WeatherCollection(properties) {
            this.values = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WeatherCollection values.
         * @member {Array.<gwm_api.IWeather>} values
         * @memberof gwm_api.WeatherCollection
         * @instance
         */
        WeatherCollection.prototype.values = $util.emptyArray;

        /**
         * Creates a new WeatherCollection instance using the specified properties.
         * @function create
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {gwm_api.IWeatherCollection=} [properties] Properties to set
         * @returns {gwm_api.WeatherCollection} WeatherCollection instance
         */
        WeatherCollection.create = function create(properties) {
            return new WeatherCollection(properties);
        };

        /**
         * Encodes the specified WeatherCollection message. Does not implicitly {@link gwm_api.WeatherCollection.verify|verify} messages.
         * @function encode
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {gwm_api.IWeatherCollection} message WeatherCollection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WeatherCollection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.values != null && message.values.length)
                for (var i = 0; i < message.values.length; ++i)
                    $root.gwm_api.Weather.encode(message.values[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WeatherCollection message, length delimited. Does not implicitly {@link gwm_api.WeatherCollection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {gwm_api.IWeatherCollection} message WeatherCollection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WeatherCollection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WeatherCollection message from the specified reader or buffer.
         * @function decode
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gwm_api.WeatherCollection} WeatherCollection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WeatherCollection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.gwm_api.WeatherCollection();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.gwm_api.Weather.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WeatherCollection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gwm_api.WeatherCollection} WeatherCollection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WeatherCollection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WeatherCollection message.
         * @function verify
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WeatherCollection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (var i = 0; i < message.values.length; ++i) {
                    var error = $root.gwm_api.Weather.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WeatherCollection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gwm_api.WeatherCollection} WeatherCollection
         */
        WeatherCollection.fromObject = function fromObject(object) {
            if (object instanceof $root.gwm_api.WeatherCollection)
                return object;
            var message = new $root.gwm_api.WeatherCollection();
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".gwm_api.WeatherCollection.values: array expected");
                message.values = [];
                for (var i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".gwm_api.WeatherCollection.values: object expected");
                    message.values[i] = $root.gwm_api.Weather.fromObject(object.values[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WeatherCollection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gwm_api.WeatherCollection
         * @static
         * @param {gwm_api.WeatherCollection} message WeatherCollection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WeatherCollection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (message.values && message.values.length) {
                object.values = [];
                for (var j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.gwm_api.Weather.toObject(message.values[j], options);
            }
            return object;
        };

        /**
         * Converts this WeatherCollection to JSON.
         * @function toJSON
         * @memberof gwm_api.WeatherCollection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WeatherCollection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WeatherCollection;
    })();

    gwm_api.Weather = (function() {

        /**
         * Properties of a Weather.
         * @memberof gwm_api
         * @interface IWeather
         * @property {number|Long|null} [timestamp] Weather timestamp
         * @property {number|null} [windspeed] Weather windspeed
         * @property {number|null} [winddirection] Weather winddirection
         * @property {number|null} [precipitation] Weather precipitation
         * @property {number|null} [temperature] Weather temperature
         * @property {number|null} [sunshine] Weather sunshine
         * @property {number|null} [pressure] Weather pressure
         * @property {number|null} [humidity] Weather humidity
         */

        /**
         * Constructs a new Weather.
         * @memberof gwm_api
         * @classdesc Represents a Weather.
         * @implements IWeather
         * @constructor
         * @param {gwm_api.IWeather=} [properties] Properties to set
         */
        function Weather(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Weather timestamp.
         * @member {number|Long} timestamp
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Weather windspeed.
         * @member {number} windspeed
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.windspeed = 0;

        /**
         * Weather winddirection.
         * @member {number} winddirection
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.winddirection = 0;

        /**
         * Weather precipitation.
         * @member {number} precipitation
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.precipitation = 0;

        /**
         * Weather temperature.
         * @member {number} temperature
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.temperature = 0;

        /**
         * Weather sunshine.
         * @member {number} sunshine
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.sunshine = 0;

        /**
         * Weather pressure.
         * @member {number} pressure
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.pressure = 0;

        /**
         * Weather humidity.
         * @member {number} humidity
         * @memberof gwm_api.Weather
         * @instance
         */
        Weather.prototype.humidity = 0;

        /**
         * Creates a new Weather instance using the specified properties.
         * @function create
         * @memberof gwm_api.Weather
         * @static
         * @param {gwm_api.IWeather=} [properties] Properties to set
         * @returns {gwm_api.Weather} Weather instance
         */
        Weather.create = function create(properties) {
            return new Weather(properties);
        };

        /**
         * Encodes the specified Weather message. Does not implicitly {@link gwm_api.Weather.verify|verify} messages.
         * @function encode
         * @memberof gwm_api.Weather
         * @static
         * @param {gwm_api.IWeather} message Weather message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Weather.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestamp);
            if (message.windspeed != null && Object.hasOwnProperty.call(message, "windspeed"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.windspeed);
            if (message.winddirection != null && Object.hasOwnProperty.call(message, "winddirection"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.winddirection);
            if (message.precipitation != null && Object.hasOwnProperty.call(message, "precipitation"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.precipitation);
            if (message.temperature != null && Object.hasOwnProperty.call(message, "temperature"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.temperature);
            if (message.sunshine != null && Object.hasOwnProperty.call(message, "sunshine"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.sunshine);
            if (message.pressure != null && Object.hasOwnProperty.call(message, "pressure"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.pressure);
            if (message.humidity != null && Object.hasOwnProperty.call(message, "humidity"))
                writer.uint32(/* id 81, wireType 1 =*/649).double(message.humidity);
            return writer;
        };

        /**
         * Encodes the specified Weather message, length delimited. Does not implicitly {@link gwm_api.Weather.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gwm_api.Weather
         * @static
         * @param {gwm_api.IWeather} message Weather message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Weather.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Weather message from the specified reader or buffer.
         * @function decode
         * @memberof gwm_api.Weather
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gwm_api.Weather} Weather
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Weather.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.gwm_api.Weather();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timestamp = reader.int64();
                    break;
                case 2:
                    message.windspeed = reader.double();
                    break;
                case 3:
                    message.winddirection = reader.double();
                    break;
                case 4:
                    message.precipitation = reader.double();
                    break;
                case 5:
                    message.temperature = reader.double();
                    break;
                case 6:
                    message.sunshine = reader.double();
                    break;
                case 7:
                    message.pressure = reader.double();
                    break;
                case 81:
                    message.humidity = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Weather message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gwm_api.Weather
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gwm_api.Weather} Weather
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Weather.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Weather message.
         * @function verify
         * @memberof gwm_api.Weather
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Weather.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.windspeed != null && message.hasOwnProperty("windspeed"))
                if (typeof message.windspeed !== "number")
                    return "windspeed: number expected";
            if (message.winddirection != null && message.hasOwnProperty("winddirection"))
                if (typeof message.winddirection !== "number")
                    return "winddirection: number expected";
            if (message.precipitation != null && message.hasOwnProperty("precipitation"))
                if (typeof message.precipitation !== "number")
                    return "precipitation: number expected";
            if (message.temperature != null && message.hasOwnProperty("temperature"))
                if (typeof message.temperature !== "number")
                    return "temperature: number expected";
            if (message.sunshine != null && message.hasOwnProperty("sunshine"))
                if (typeof message.sunshine !== "number")
                    return "sunshine: number expected";
            if (message.pressure != null && message.hasOwnProperty("pressure"))
                if (typeof message.pressure !== "number")
                    return "pressure: number expected";
            if (message.humidity != null && message.hasOwnProperty("humidity"))
                if (typeof message.humidity !== "number")
                    return "humidity: number expected";
            return null;
        };

        /**
         * Creates a Weather message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gwm_api.Weather
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gwm_api.Weather} Weather
         */
        Weather.fromObject = function fromObject(object) {
            if (object instanceof $root.gwm_api.Weather)
                return object;
            var message = new $root.gwm_api.Weather();
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            if (object.windspeed != null)
                message.windspeed = Number(object.windspeed);
            if (object.winddirection != null)
                message.winddirection = Number(object.winddirection);
            if (object.precipitation != null)
                message.precipitation = Number(object.precipitation);
            if (object.temperature != null)
                message.temperature = Number(object.temperature);
            if (object.sunshine != null)
                message.sunshine = Number(object.sunshine);
            if (object.pressure != null)
                message.pressure = Number(object.pressure);
            if (object.humidity != null)
                message.humidity = Number(object.humidity);
            return message;
        };

        /**
         * Creates a plain object from a Weather message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gwm_api.Weather
         * @static
         * @param {gwm_api.Weather} message Weather
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Weather.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.windspeed = 0;
                object.winddirection = 0;
                object.precipitation = 0;
                object.temperature = 0;
                object.sunshine = 0;
                object.pressure = 0;
                object.humidity = 0;
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            if (message.windspeed != null && message.hasOwnProperty("windspeed"))
                object.windspeed = options.json && !isFinite(message.windspeed) ? String(message.windspeed) : message.windspeed;
            if (message.winddirection != null && message.hasOwnProperty("winddirection"))
                object.winddirection = options.json && !isFinite(message.winddirection) ? String(message.winddirection) : message.winddirection;
            if (message.precipitation != null && message.hasOwnProperty("precipitation"))
                object.precipitation = options.json && !isFinite(message.precipitation) ? String(message.precipitation) : message.precipitation;
            if (message.temperature != null && message.hasOwnProperty("temperature"))
                object.temperature = options.json && !isFinite(message.temperature) ? String(message.temperature) : message.temperature;
            if (message.sunshine != null && message.hasOwnProperty("sunshine"))
                object.sunshine = options.json && !isFinite(message.sunshine) ? String(message.sunshine) : message.sunshine;
            if (message.pressure != null && message.hasOwnProperty("pressure"))
                object.pressure = options.json && !isFinite(message.pressure) ? String(message.pressure) : message.pressure;
            if (message.humidity != null && message.hasOwnProperty("humidity"))
                object.humidity = options.json && !isFinite(message.humidity) ? String(message.humidity) : message.humidity;
            return object;
        };

        /**
         * Converts this Weather to JSON.
         * @function toJSON
         * @memberof gwm_api.Weather
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Weather.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Weather;
    })();

    return gwm_api;
})();

module.exports = $root;
