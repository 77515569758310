import React, { Component, createRef, RefObject } from 'react';
import { range, sortBy, sortedUniqBy } from 'lodash';
import moment from 'moment';
import { ChartItem, LegendValue } from '../models/ChartValue';
import { ComposedChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, ReferenceArea, Legend, Label, Tooltip, TooltipProps } from 'recharts';
import Meetpunt from 'models/Meetpunt';
import LegendWrapper from './LegendWrapper';
import { DateFilter } from './DateFilter';
import { wrap, releaseProxy, Remote } from 'comlink';
import GraphWorker from 'workers/GraphWorker';
import { gwm_api } from 'protobuf';
import { COLORS } from '../utils/constants';

interface ValidationGraphProps {
    unit: 'mv' | 'nap';
    info: [Meetpunt, gwm_api.SampleCollection];
    data: ChartItem[];
    selection?: ChartItem[]

    onSelection?: (samples?: gwm_api.ISample[], selection?: ChartItem[]) => void;
}

interface ValidationGraphState {
    data?: ChartItem[];
    selection?: ChartItem[];
    selectionDetailed?: gwm_api.ISample[];
    limits: [number, number, number, number];

    zoomMode: boolean;

    refAreaLeft?: number;
    refAreaRight?: number;
    refAreaTop?: number;
    refAreaBottom?: number;

    visibility: Record<string, boolean>;

    zoomed: boolean;
}

export class ValidationGraph extends Component<ValidationGraphProps, ValidationGraphState> {
    constructor(props: Readonly<ValidationGraphProps>) {
        super(props);

        this.state = {
            visibility: {},
            zoomed: false,
            limits: [0, 0, 0, 0],
            selection: props.selection,
            zoomMode: false
        };

        this.tooltipRef = createRef<HTMLDivElement>();
        this.chartRef = createRef();
        this.shiftPressed = false;

        this.zoom = this.zoom.bind(this);
        this.reset = this.reset.bind(this);
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
        this.onDateRangeReset = this.onDateRangeReset.bind(this);
        this.onGraphZoomAction = this.onGraphZoomAction.bind(this);
        this.onGraphSelectAction = this.onGraphSelectAction.bind(this);
    }

    readonly tooltipRef: RefObject<HTMLDivElement>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly chartRef: RefObject<any>;

    private zoomIntervalHandle?: number;
    private refAreaRight?: number;
    private refAreaBottom?: number;
    private worker?: Worker;
    private adapter?: Remote<GraphWorker>;

    private shiftPressed: boolean;

    componentDidMount(): void {
        this.worker = new Worker(`${location.pathname}workers/GraphWorker.js`, { type: 'module' });
        this.adapter = wrap<GraphWorker>(this.worker);

        this.adapter.setData(this.props.data).then(() => {
            this.setState({
                visibility: this.getDefaultVisibility()
            }, () => this.reset());
        });

        window.onkeydown = (e) => this.shiftPressed = e.ctrlKey || e.shiftKey;
        window.onkeyup = (e) => this.shiftPressed = e.ctrlKey || e.shiftKey;
    }

    componentDidUpdate(prevProps: Readonly<ValidationGraphProps>, prevState: Readonly<ValidationGraphState>): void {
        if (this.state.refAreaLeft != prevState.refAreaLeft || this.state.refAreaTop != prevState.refAreaTop) {
            if (this.state.refAreaLeft) {
                this.zoomIntervalHandle = setInterval(() => {
                    if (this.state.refAreaRight != this.refAreaRight || this.state.refAreaBottom != this.refAreaBottom) {
                        this.setState({ 
                            refAreaRight: this.refAreaRight,
                            refAreaBottom: this.refAreaBottom 
                        });
                    }
                }, 100);

            } else {
                clearInterval(this.zoomIntervalHandle);
                this.zoomIntervalHandle = undefined;
                this.refAreaRight = undefined;
                this.refAreaBottom = undefined;
            }
        }
        
        if (this.props.data != prevProps.data) {
            if (this.state.selection) {
                const timestamps = this.state.selection.map(x => x.time);
                this.setState({ selection: this.props.data.filter(x => timestamps.includes(x.time))});
            }
            this.setState({ data: [] }, () => {
                this.adapter?.setData(this.props.data).then(() => {
                    this.setState({
                        visibility: this.getDefaultVisibility()
                    }, () => this.reset());
                });
            });
        }
        
        if (this.state.selectionDetailed != prevState.selectionDetailed) {
            this.props.onSelection?.(this.state.selectionDetailed, this.state.selection);
        }
    }

    componentWillUnmount(): void {
        if (this.zoomIntervalHandle != undefined) {
            clearInterval(this.zoomIntervalHandle);
            this.zoomIntervalHandle = undefined;
        }

        window.onkeydown = null;
        window.onkeyup = null;

        this.adapter?.[releaseProxy]?.();
        this.worker?.terminate();
    }

    getDefaultVisibility(): Record<string, boolean> {
        return Object.assign({}, ...[
            ...range(0, this.props.data[0].values.length + 0)
                .map(id => `values[${id}]`)
                .flatMap(id => ['valid', 'released', 'raw', 'manual'].map(v => `${id}.${v}`))
        ].map(v => ({ [v]: v.endsWith('raw') || v.endsWith('released') || v.endsWith('valid') })));
    }

    async zoom(min: number, max: number): Promise<void> {
        if (!this.adapter) return;

        const keys = this.props.data.map(v => v.time);
        let startIndex = keys.indexOf(min);
        if (startIndex == -1) {
            startIndex = keys.indexOf(sortBy(keys, v => Math.abs(v - min))[0]);
        }
        let endIndex = keys.indexOf(max);
        if (endIndex == -1) {
            endIndex = keys.indexOf(sortBy(keys, v => Math.abs(v - max))[0]);
        }

        // Graph can only be drawn for 2 or more data points 
        if (this.props.data.slice(startIndex, endIndex).length > 1) {
            await this.adapter.setSlice([startIndex, endIndex]);
    
            const data = await this.adapter.sampleData();
            const limits = await this.adapter.getLimits(this.state.visibility);

            this.setState({
                data: data,
                limits: limits,
                refAreaLeft: undefined,
                refAreaRight: undefined,
                zoomMode: false,
                zoomed: true
            });
        }
    }

    async reset(): Promise<void> {
        if (!this.adapter) return;

        await this.adapter.setSlice([undefined, undefined]);

        const data = await this.adapter.sampleData();
        const limits = await this.adapter.getLimits(this.state.visibility);

        this.setState({
            data: data,
            limits: limits,
            zoomed: false
        });
    }

    select(xmin: number, ymin: number, xmax: number, ymax: number): void {
        const selection = this.state.data
            ?.filter(x => x.time >= xmin && x.time <= xmax && 
                x.values.some(v => typeof v.raw == 'number' && v.raw >= ymin && v.raw <= ymax));
        if (selection?.length) {
            const selectionDetailed = this.props.info[1].values
                .filter(x => {
                    const value = this.props.unit == 'mv' && x.value ? this.props.info[0].napLevel - x.value : x.value;
                    return x.timestamp && !x.manual && value && x.timestamp >= xmin && x.timestamp <= xmax && value >= ymin && value <= ymax;
                });
    
            this.setState({
                selection: selection,
                selectionDetailed: selectionDetailed
            });
        } else {
            this.setState({
                selection: undefined,
                selectionDetailed: undefined
            });
        }
    }

    getTickInfo(): { format: string; ticks: number[]; } {
        let format = 'DD MMM YYYY HH:mm';
        const ticks: number[] = [];

        if (this.state.limits) {
            //const start = moment(this.state.limits[0]);
            const range = (this.state.limits[2] - this.state.limits[0]);
            if (range / 31536000000 > 3) { // year
                format = 'YYYY';
            } else if (range / 2592000000 > 6) { // month
                format = 'MMM YYYY';
            } else if (range / 86400000 > 14) { // day
                format = 'DD MMM';
            } else if (range / 86400000 > 1) { // day
                format = 'ddd HH:00';
            } else {
                format = 'HH:mm';
            }

            const count = 8;
            const interval = Math.round(range / (count - 1));

            for (let i = 0; i < count; i++) {
                ticks.push(this.state.limits[0] + interval * i);
            }

            const uniqTicks = sortedUniqBy(ticks, v => moment(v).format(format));
            if (uniqTicks.length !== ticks.length) {
                const count = uniqTicks.length;
                const interval = Math.round(range / (count - 1));

                ticks.splice(0, ticks.length);
                for (let i = 0; i < count; i++) {
                    ticks.push(this.state.limits[0] + interval * i);
                }
            }
        }

        return {
            format: format,
            ticks: ticks //sortedUniqBy(ticks, v => moment(v).format(format))
        };
    }

    async onToggleVisibility(...values: [key: string, state?: boolean][]): Promise<void> {
        const visibility = { ...this.state.visibility };
        for (const [key, state] of values) {
            visibility[key] = state != undefined ? state : visibility[key] != undefined ? !visibility[key] : false;
        }
        this.setState({ visibility: visibility }, async () => {
            if (this.state.data && this.adapter) {
                const limits = await this.adapter.getLimits(this.state.visibility);
                this.setState({ limits: limits });
            }
        });
    }

    onGraphZoomAction(): void {
        let { refAreaLeft, refAreaRight } = this.state;

        if (refAreaLeft === refAreaRight || refAreaLeft === undefined || refAreaRight === undefined) {
            this.setState(() => ({
                refAreaLeft: undefined,
                refAreaRight: undefined,
            }));
            return;
        }

        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        this.zoom(refAreaLeft, refAreaRight);
    }

    onGraphSelectAction(): void {

        let { refAreaLeft, refAreaRight, refAreaTop, refAreaBottom } = this.state;

        if (refAreaLeft === refAreaRight || refAreaBottom === refAreaTop || 
            refAreaLeft === undefined || refAreaRight === undefined || 
            refAreaBottom === undefined || refAreaTop === undefined) {

            this.setState(() => ({
                refAreaLeft: undefined,
                refAreaRight: undefined,
                refAreaBottom: undefined,
                refAreaTop: undefined,
            }));
            return;
        }

        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
        if (refAreaBottom > refAreaTop) [refAreaTop, refAreaBottom] = [refAreaBottom, refAreaTop];

        this.select(refAreaLeft, refAreaBottom, refAreaRight, refAreaTop);

        this.setState({
            refAreaLeft: undefined,
            refAreaRight: undefined,
            refAreaTop: undefined,
            refAreaBottom: undefined
        });
    }

    onDateRangeChanged(min: number, max: number): void {
        this.zoom(min, max);
    }

    onDateRangeReset(): void {
        this.reset();
    }

    getKeys(): { key: string; value: LegendValue; color: string; lineColor?: string; }[] {
        const sample = this.props.data[0];
        const keys = sample.values
            .flatMap((v, i) => ['valid', 'released', 'raw', 'manual'].map(k => ({
                key: `values[${i}].${k}`,
                value: {
                    label: app.translator.translate(k, {
                        context: 'GWM',
                        format: 'lc'
                    }),
                    groupId: v.label
                } as LegendValue,
                color: k == 'manual' ? '#f60' : k == 'raw' ? '#a1a1a1' : COLORS[i % COLORS.length],
                lineColor: k == 'released' ? '#f9c5fc' : undefined
            })));
        return keys;
    }

    getLegendNode(keys?: { key: string; value: LegendValue; color: string; lineColor?: string; }[]): React.ReactElement {
        const payload = (keys ?? this.getKeys()).map(k => ({
            id: k.key,
            value: k.value,
            color: k.lineColor ?? k.color
        }));
        return <Legend
            payload={payload}
            content={<LegendWrapper simple
                visibility={this.state.visibility}
                onClick={(...v) => this.onToggleVisibility(...v.map(x => [x] as [string]))} />}
        />;
    }

    getDomains(): [domainX: [number, number], domainY: [number, number]] {

        const dataPadding = ((this.state.limits?.[3] ?? 0) - (this.state.limits?.[1] ?? 0)) * .1;
        const dataPaddingX = ((this.state.limits?.[2] ?? 0) - (this.state.limits?.[0] ?? 0)) * .1;

        const yAxisDomain: [number, number] = [
            this.state.limits[1] - dataPadding,
            this.state.limits[3] + dataPadding
        ];
        const xAxisDomain: [number, number] = [
            this.state.limits[0] - dataPaddingX,
            this.state.limits[2] + dataPaddingX
        ];

        return [xAxisDomain, yAxisDomain];
    }

    render(): JSX.Element | null {
        const tickInfo = this.getTickInfo();

        const keys = this.getKeys();

        const [xAxisDomain, yAxisDomain] = this.getDomains();

        const visible = keys.filter(k => this.state.visibility[k.key] && this.state.visibility[k.value.groupId] != false);

        const legendNode = this.getLegendNode(keys);

        return (<>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex flex-column w-100 overflow-auto">
                    <DateFilter 
                        dateRangeMin={this.props.data[0].time}
                        dateRangeMax={this.props.data[this.props.data.length - 1].time}
                        current={[
                            this.state.limits?.[0] ?? this.props.data[0].time,
                            this.state.limits?.[2] ?? this.props.data[this.props.data.length - 1].time
                        ]}
                        onDateRangeChanged={this.onDateRangeChanged}
                        onDateRangeReset={this.onDateRangeReset}
                        onClear={this.reset} />

                    <div className="graph user-select-none">
                        {this.state.data ? (
                            <>
                                <ResponsiveContainer width="100%" height={350} debounce={200}>
                                    <ComposedChart data={this.state.data} margin={{ top: 5, left: 0, right: 5, bottom: 15 }}
                                        ref={this.chartRef}
                                        onMouseDown={(e) => {
                                            if (!e.activeLabel || typeof e.chartX != 'number' || typeof e.chartY != 'number') return;
                                    
                                            const chartState = this.chartRef.current?.state;
                                            const height = Number(chartState?.prevHeight ?? 0) - (Number(chartState?.offset.top ?? 0) + Number(chartState?.offset.bottom ?? 0));
                                            const width = Number(chartState?.prevWidth ?? 0) - (Number(chartState?.offset.left ?? 0) + Number(chartState?.offset.right ?? 0));
                                            
                                            const reversed = this.props.unit == 'nap';
                                            const xOffset = (e.chartX - Number(chartState?.offset.left ?? 0)) * ((xAxisDomain[1] - xAxisDomain[0]) / width);
                                            const yOffset = (e.chartY - Number(chartState?.offset.top ?? 0)) * ((yAxisDomain[1] - yAxisDomain[0]) / height);

                                            const activeLabelX = Number(xAxisDomain[0] + xOffset);
                                            const activeLabelY = Number(!reversed ? yAxisDomain[0] + yOffset : yAxisDomain[1] - yOffset);

                                            if (activeLabelX && activeLabelY) {
                                                if (this.shiftPressed) {
                                                    this.setState({
                                                        refAreaLeft: activeLabelX,
                                                        refAreaRight: activeLabelX,
                                                        zoomMode: true
                                                    });
                                                } else {
                                                    this.setState({
                                                        refAreaLeft: activeLabelX,
                                                        refAreaRight: activeLabelX,
                                                        refAreaTop: activeLabelY,
                                                        refAreaBottom: activeLabelY,
                                                    });
                                                }
                                            }
                                        }}
                                        onMouseMove={this.state.refAreaLeft ? (e) => {
                                            if (typeof e.chartX != 'number' || typeof e.chartY != 'number') return;
                                    
                                            const chartState = this.chartRef.current?.state;
                                            const height = Number(chartState?.prevHeight ?? 0) - (Number(chartState?.offset.top ?? 0) + Number(chartState?.offset.bottom ?? 0));
                                            const width = Number(chartState?.prevWidth ?? 0) - (Number(chartState?.offset.left ?? 0) + Number(chartState?.offset.right ?? 0));
                                            
                                            const reversed = this.props.unit == 'nap';
                                            const xOffset = (e.chartX - Number(chartState?.offset.left ?? 0)) * ((xAxisDomain[1] - xAxisDomain[0]) / width);
                                            const yOffset = (e.chartY - Number(chartState?.offset.top ?? 0)) * ((yAxisDomain[1] - yAxisDomain[0]) / height);

                                            const activeLabelX = Number(xAxisDomain[0] + xOffset);
                                            const activeLabelY = Number(!reversed ? yAxisDomain[0] + yOffset : yAxisDomain[1] - yOffset);
                                            
                                            if (activeLabelX && activeLabelY) {
                                                this.refAreaRight = activeLabelX;
                                                this.refAreaBottom = !this.state.zoomMode ? activeLabelY : undefined;
                                            } else {
                                                this.setState({
                                                    refAreaLeft: undefined,
                                                    refAreaRight: undefined,
                                                    refAreaTop: undefined,
                                                    refAreaBottom: undefined,
                                                    zoomMode: false
                                                });
                                            }
                                        } : undefined}
                                        onMouseUp={() => {
                                            if (this.state.zoomMode) {
                                                if (this.state.refAreaLeft) 
                                                    this.onGraphZoomAction();
                                            } else {
                                                if (this.state.refAreaLeft && this.state.refAreaTop) 
                                                    this.onGraphSelectAction();
                                            }
                                        }}
                                    >

                                        <CartesianGrid strokeDasharray="3 3" />

                                        <XAxis dataKey="time" allowDataOverflow
                                            domain={xAxisDomain}
                                            scale="time"
                                            type="number"
                                            ticks={tickInfo.ticks}
                                            tickFormatter={(v) => moment(v).format(tickInfo.format)}
                                            interval={0} allowDecimals={false}
                                        >
                                            <Label position='centerBottom' style={{ textAnchor: 'middle' }} dy={15}
                                                value="Datum" />
                                        </XAxis>

                                        <YAxis orientation="left"
                                            domain={yAxisDomain}
                                            scale="linear" reversed={this.props.unit == 'mv'} allowDataOverflow>
                                            <Label angle={-90} position='insideLeft' style={{ textAnchor: 'middle' }}
                                                value={`Grondwaterstand (${this.props.unit == 'mv' ? 'cm-mv' : 'cm NAP'})`} />
                                        </YAxis>

                                        {/* <Tooltip content={<TooltipWrapper container={this.tooltipRef.current ?? undefined}
                                            labels={Object.assign({}, ...keys.map(k => ({ [k.key]: `${k.value.groupId}: ${k.value.label}` })))}
                                        />} /> */}

                                        <Tooltip content={(p: TooltipProps<number, string>) => {
                                            const point = p.payload?.[0]?.payload as ChartItem | undefined;
                                            return point ? (
                                                <span className="badge badge-secondary">{point.values[0]?.raw?.toFixed(1) ?? '-'} ({moment(point.time).format('DD MMM YYYY HH:mm')})</span>
                                            ) : <span></span>;
                                        }} />

                                        {visible.filter(k => !k.key.endsWith('manual')).reverse().map(k => (
                                            <Line key={`chart-line-${k.key}`} data={this.state.data} type="natural" dataKey={k.key} fill={k.color} stroke={k.lineColor ?? k.color} strokeWidth={k.lineColor ? 10 : undefined} connectNulls={false}
                                                dot={{ r: 1 }} activeDot={{ r: 8 }} />
                                        ))}

                                        {visible.filter(k => k.key.endsWith('manual')).reverse().map(k => (
                                            <Line key={`chart-line-${k.key}`} data={this.state.data} type="basis" dataKey={k.key} fill={k.color} stroke={k.color} connectNulls={false}
                                                dot={{ r: 4 }} activeDot={{ r: 8 }} width={0} />
                                        ))}

                                        {this.state.selection ? (
                                            <Line key={'chart-line-selection'} data={this.state.selection} type="natural" dataKey={'values[0].raw'} fill={'#fcba03'} stroke={'#f7d36f'} strokeWidth={4} connectNulls={false}
                                                dot={{ r: 1 }} activeDot={{ r: 4 }} />
                                        ) : undefined}

                                        {this.state.refAreaLeft ? (
                                            <ReferenceArea x1={this.state.refAreaLeft} x2={this.state.refAreaRight} y1={this.state.refAreaTop} y2={this.state.refAreaBottom} strokeOpacity={0.3} />
                                        ) : undefined}
                                    </ComposedChart>
                                </ResponsiveContainer>

                                <div className="d-flex flex-row justify-content-between align-items-top w-100 px-3">
                                    {legendNode}
                                    
                                    <div>
                                        <span className="badge badge-secondary">
                                            {app.translator.translate('tip: houdt \'shift\' ingedrukt en sleep over de grafiek om in te zoomen.')}
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex flex-column w-100 px-3" ref={this.tooltipRef} />
                            </>
                        ) : undefined}
                    </div>
                </div>
            </div>
        </>);
    }

}

export default ValidationGraph;