import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormControlValue, FormValue } from '@tauw/viewer-base';
import { mapValues } from 'lodash';
import GraphAnnotation from 'models/GraphAnnotation';
import moment from 'moment';
import { Component } from 'react';
import { DATE_FORMAT } from '../utils/constants';

interface AnnotationEditorProps {
    graphAnnotation?: GraphAnnotation;
    dateRange?: [Date, Date];

    onCancelClicked?: () => void;
    onCreateClicked?: (graphAnnotation: GraphAnnotation) => void;
    onUpdateClicked?: (graphAnnotation: GraphAnnotation) => void;
    onDeleteClicked?: (graphAnnotation: GraphAnnotation) => void;
}

interface AnnotationEditorState {
    form: Form;
}

interface Form {
    startdate: FormValue<string>;
    enddate: FormValue<string>;
    annotation: FormValue<string>;
}

export default class AnnotationEditor extends Component<AnnotationEditorProps, AnnotationEditorState> {
    constructor(props: Readonly<AnnotationEditorProps>) {
        super(props);

        // Initializes form state based on new or existing assessment
        let form: Form;
        if (props.graphAnnotation) {
            form = mapValues(props.graphAnnotation, element => element ? ({ value: element, valid: true }) : null) as Form;

            if (form.startdate?.value)
                form.startdate.value = moment(form.startdate.value).format(DATE_FORMAT);

            if (form.enddate?.value)
                form.enddate.value = moment(form.enddate.value).format(DATE_FORMAT);
        }
        else {
            form = {
                startdate: props.dateRange ? { value: moment(props.dateRange[0]).format(DATE_FORMAT), valid: true } : null,
                enddate: props.dateRange ? { value: moment(props.dateRange[1]).format(DATE_FORMAT), valid: true } : null,
                annotation: null,
            };
        }

        this.state = {
            form: form,
        };

        this.onFormChanged = this.onFormChanged.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
    }

    onFormChanged(prop: FormControlValue.Default): void {
        this.setState(state => ({
            form: {
                ...state.form,
                [prop.name]: {
                    value: prop.value,
                    valid: prop.valid
                }
            }
        }));
    }

    onCancelClicked(): void {
        this.props.onCancelClicked?.();
    }

    onDeleteClicked(): void {
        this.props.onDeleteClicked?.(this.props.graphAnnotation as GraphAnnotation);
    }

    onSaveClicked(): void {
        // Check validation states
        for (const prop in this.state.form) {
            const value = this.state.form[prop];
            if (value === undefined) continue;

            if (value === null || !value.valid) { // prop is null or invalid
                return;
            }
        }

        const graphAnnotation = mapValues(this.state.form, prop => prop ? prop.value : undefined) as unknown as GraphAnnotation;
        graphAnnotation.startdate = moment.utc(graphAnnotation.startdate, DATE_FORMAT).toDate();
        graphAnnotation.enddate = moment.utc(graphAnnotation.enddate, DATE_FORMAT).toDate();


        if (this.props.graphAnnotation)
            this.props.onUpdateClicked?.(graphAnnotation);
        else
            this.props.onCreateClicked?.(graphAnnotation);
    }

    render(): JSX.Element {
        const valid = Object.keys(this.state.form).every(key => this.state.form[key] === undefined || this.state.form[key]?.valid);

        return (
            <div className="annotation-editor overflow-auto">
                {this.props.graphAnnotation && <button className="btn btn-danger float-right" onClick={this.onDeleteClicked}>
                    <FontAwesomeIcon icon={faTrashCan} />&nbsp;
                    {app.translator.translate('Verwijderen')}
                </button>}
                <div className="container">
                    <div className="col-8 mx-auto">
                        <h2 className="mb-5 text-center">{this.props.graphAnnotation ? 'Wijzig opmerking' : 'Nieuwe opmerking'}</h2>
                        <div className="row mb-2">
                            <div className="col">
                                <FormControl.Date displayName={'Begindatum'} dateFormat={DATE_FORMAT}
                                    initialValue={this.state.form.startdate?.value}
                                    name={'startdate'} feedback={true} required
                                    onValueChanged={this.onFormChanged} vertical />
                            </div>
                            <div className="col">
                                <FormControl.Date displayName={'Einddatum'} dateFormat={DATE_FORMAT}
                                    initialValue={this.state.form.enddate?.value}
                                    name={'enddate'} feedback={true} required
                                    onValueChanged={this.onFormChanged} vertical />
                            </div>
                        </div>
                        <div className="mb-2">
                            <FormControl.Textarea displayName={'Opmerking'}
                                initialValue={this.state.form.annotation?.value} rows={5}
                                name={'annotation'} feedback={true} required
                                onValueChanged={this.onFormChanged} vertical />
                        </div>
                        <div className="mt-5">
                            <button className="btn btn-dark-cyan btn-block" disabled={!valid} onClick={valid ? this.onSaveClicked : undefined}>
                                {app.translator.translate('Opslaan')}
                            </button>
                            <button className="btn btn-secondary btn-block" onClick={this.onCancelClicked}>
                                {app.translator.translate('Annuleren')}
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
