import '@tauw/viewer-base/build/index.css';
import './index.scss';

import ReactDOM from 'react-dom';
import { App, MapInfo, LayerOverview, Legend } from '@tauw/viewer-base';
import GroundwaterGraphs from './widgets/GroundwaterGraphs';
import { GwmPublicApi } from './general/api';
import config from './config/config.json';
import PublicSettings from './models/PublicSettings';
import Maintenance from './widgets/Maintenance';
import PiezometerFactsheet from './widgets/PiezometerFactsheet';

GwmPublicApi.configure(config.api.gwm.url);

async function start() {
    const search = new URLSearchParams(location.search);
    const siteid = Number(search.get('siteid') ?? '');

    const settings: PublicSettings = (await GwmPublicApi.getSettings({ siteid })).data ?? {
        tools: {
            factsheet: true,
            maintenance: false,
            graph: {
                showRaw: false,
                showReleased: false,
                showComments: false,
                showGrouping: true,
                showExport: true
            }
        }
    };

    const GwmApp = () => {
        const topWidgets =[
            <Legend key={0} id={'legend'} />,
            <LayerOverview key={1} id={'layer-overview'} />
        ];
        if (settings.tools?.factsheet)
            topWidgets.push(<PiezometerFactsheet key={2} id={'factsheet'} action={['piezometers']} lite />);
        if (settings.tools?.maintenance)
            topWidgets.push(<Maintenance key={3} id={'maintenance'} action={['piezometers']} />);
    
        return <App
            name={settings.title ?? 'Grondwatermeetnet Viewer'}
            mapInfo={config.mapInfo as Record<string, MapInfo>}
            api={config.api.tegsis}
            logo={settings.defaultLogo ? undefined : false}
            subLogo={settings.clientLogo}
            loginManager={{ 
                mode: 'hide-ui',
                site: true, 
                project: false, 
                client: false
            }}
            searchMode="geocoder"
            topWidgets={topWidgets}
            widgets={[
                <GroundwaterGraphs key={3} id={'graphs'} action={['piezometers']} lite
                    showRaw={settings.tools?.graph?.showRaw ?? false}
                    showReleased={settings.tools?.graph?.showReleased ?? false}
                    showComments={settings.tools?.graph?.showComments ?? false}
                    showGrouping={settings.tools?.graph?.showGrouping ?? true}
                    showExport={settings.tools?.graph?.showExport ?? true} />,
            ]} />;
    };
    
    // Initialize main React entry point
    ReactDOM.render(<GwmApp />, document.getElementById('root'));
}

start();
